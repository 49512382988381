# setup application-wide event listening here
window.intake.run(($rootScope, $http, $location, $log, $state, $builder, Restangular, NotificationService, AuthService) ->
  # hook into the route change process here
  $rootScope.$on "$stateChangeStart", (event, toState, toParams, fromState, fromParams) ->
    $rootScope.pageTitle = toState.data.pageTitle
    $rootScope.params = toParams

    obsoleteRoutes = ['surveys', 'patient_surveys']
    if toState.name in obsoleteRoutes
      event.preventDefault();
      $state.go('patientErrors', { error: 'session_invalid' })

  $rootScope.$on 'authenticated', (authResult) ->
    $log.information authResult

  Restangular.setDefaultHttpFields({'Origin': 'http://localhost:3000'})

  # All REST interceptors must conclude with returning the response or calls will fail!

  # loading handles turning on and off our spinners globally
  $rootScope.loading = 0
  Restangular.addRequestInterceptor (response) ->
    $rootScope.loading++
    return response

  Restangular.addResponseInterceptor (response) ->
    $rootScope.loading--
    return response

  Restangular.setErrorInterceptor (response) ->
    $rootScope.loading--

    # TODO: remove me:
    # tell the NotificationService
    NotificationService.emit('intake-warning', response)

    if response.status == 401
      if AuthService.isPhrUser()
          AuthService.expireAuthToken()

      if (AuthService.getEnvironment() == 'development'  || AuthService.getEnvironment() == 'test')
        $state.go('dev_landing_page', {})
      else
        # for PhrUser: auth credentials are bad (session timeout) go to intake login page
        if AuthService.isPhrUser()
          $state.go('patientErrors', { error: 'session_timeout' })

        if AuthService.isProvider()
          # if we're previewing the form as a provider
          if $state.current.name == 'preview.forms.show' || $state.current.name == 'preview.forms.question'
            $state.go('patientErrors', { error: 'session_timeout_ehr' })
          else
            $state.go('patientErrors', { error: 'session_invalid'})

    return response

  # register our custom components with the builder
  $builder.registerComponent 'personalInfo',
    group: 'PracticeFusion'
    label: 'Patient personal information'
    description: 'Patient name, Date of birth, Sex'
    placeholder: 'collects First name, Last name, Date of birth, gender'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'contactInfo',
    group: 'PracticeFusion'
    label: 'Patient contact information'
    description: 'Address, Email, Phone'
    placeholder: 'collects Name, Street address, Zip, State, Country, Email, Phone'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'emergencyContactInfo',
    group: 'PracticeFusion'
    label: 'Emergency contact information'
    description: 'Name, Relationship with patient, Address, Email, Phone'
    placeholder: 'collects Street address, Zip, State, Country, Email, Phone'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'smokingStatus',
    group: 'PracticeFusion'
    label: 'Smoking status'
    description: 'Smoking'
    placeholder: 'collects Smoking information'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'insurance',
    group: 'PracticeFusion'
    label: 'Insurance'
    description: 'Insurance company name, Insurance plan, Insurance ID, Effective date, Type'
    placeholder: 'collects Insurance company name, Insurance plan, Insurance ID, Effective date, Type'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'preferredLanguage',
    group: 'PracticeFusion'
    label: 'Preferred language'
    description: 'Preferred language'
    placeholder: 'collects Preferred language'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'ethnicity',
    group: 'PracticeFusion'
    label: 'Ethnicity'
    description: 'Ethnicity'
    placeholder: 'collects Ethnicity'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'race',
    group: 'PracticeFusion'
    label: 'Race'
    description: 'Race'
    placeholder: 'collects Race'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'symptoms',
    group: 'PracticeFusion'
    label: 'Symptoms'
    description: 'Symptoms of patient'
    placeholder: 'collects Symptoms of patient'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'allergies',
    group: 'PracticeFusion'
    label: 'Allergies'
    description: 'Current and past allergies'
    placeholder: 'collects current & past allergies'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'medications',
    group: 'PracticeFusion'
    label: 'Medications'
    description: 'Current medications, add new'
    placeholder: 'collects any updates to the current medications and allows to add new medication'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-not-editable.html'

  $builder.registerComponent 'patientSignature',
    group: 'Default'
    label: 'Patient signature on document'
    description: 'Document, signature, date'
    placeholder: 'allows you to enter document content and receive patient signature and agreement with time stamp.'
    required: no
    editable: yes
    validationOptions: []
    templateUrl: 'provider/forms/questions/_question-patient-signature.html'
    popoverTemplateUrl: 'provider/forms/questions/_popover-editable.html'
)
