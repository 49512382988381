intakesModule = angular.module('intakesModule')
intakesModule.directive('questionSymptoms', ($rootScope, $log, $http, SymptomsService) ->
  templateUrl: "patient/intakes/questions/pre-built/symptoms/q-symptoms.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.init = ->
      # define maxRelated first so all /related calls have it defined
      scope.maxRelated = 5
      # max for autocomplete results
      scope.maxResults = 10

      if scope.answer?.data?[0]
        scope.selected = scope.answer.data
        scope.getRelated()
      else
        scope.selected = []
        # assign initial common symptoms
        scope.common = SymptomsService.getCommon()

    # add a symptom from the autocomplete list OR add a custom symptom
    scope.add = (symptom, query, $label, isCustomValue) ->
      # build a symptom object if the user enters custom text
      if isCustomValue
        symptom = {
          name: symptom,
          value: ''
        }
      # add the item to the selected list if it's not a duplicate
      if !_.includes(_.map(scope.selected, 'name'), symptom.name) && !_.isEmpty(symptom.name)
        scope.$emit 'close-typeahead-popup'
        scope.selected.push(symptom)
        # remove symptom from the common list if it is part of it
        scope.common = _.without(scope.common, symptom)
        scope.getRelated()

    # remove a selected symptom
    scope.remove = (symptom) ->
      scope.selected = _.without(scope.selected, symptom)
      if scope.selected.length > 0
        scope.getRelated()
      if scope.selected.length is 0
        scope.common = SymptomsService.getCommon()

    # get autocomplete symptoms list for query
    scope.get = (query) ->
      SymptomsService.autocomplete(query, scope.maxResults).then (response) ->
        # remove responses that have the name already in the selected list
        return _.reject(response, (symptom) ->
          return _.includes(_.map(scope.selected, 'name'), symptom.name)
        )

    # return the related symptoms for selected symptoms
    scope.getRelated = (item, model, label) ->
      scope.selected.push(item) if item

      SymptomsService.getRelated(scope.selected, scope.maxRelated).then (response) ->
        scope.common = response

    # bypassing CSS error where border shows up if empty <ul> has any whitespaces in it
    scope.getBorder = ->
      "border: none" if scope.selected.length is 0

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}

    scope.$watch 'selected', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    scope.init()
)

