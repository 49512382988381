import { parse, validate } from 'uuid';

angular.module('globalHelpersModule').factory 'UtilityService', ($log) ->
  compareGuids: (guid1, guid2) ->
    try
      bytes1 = parse(guid1)
      bytes2 = parse(guid2)
      # difference returns [] for equal arrays
      difference = _.difference(bytes1, bytes2)
      return difference.length == 0
    catch
      return false

  truncate: (string, maxCharacters) ->
    substring = string.substring(0, maxCharacters)
    if string.length > maxCharacters
      return substring + "..."
    else
      return substring

  isValidGuid: (guid) ->
    return validate(guid)