intakesModule = angular.module('intakesModule')
intakesModule.directive('questionEmergencyContactInfo', ($log, RegionService, RelationshipService) ->
  templateUrl: "patient/intakes/questions/pre-built/emergency-contact-info/q-emergency-contact-info.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.relationships = RelationshipService.findAll()
    scope.countries = RegionService.getCountries()
    scope.states = RegionService.getUSStates()
    scope.contact = {}

    scope.init = ->
      if scope.answer?.data
        scope.contact = scope.answer.data

    scope.phoneNumberPattern = (->
      regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/
      test: (value) ->
        regexp.test value
    )()

    # decide whether to show state or not.
    scope.showState = ->
      if scope.contact.country?
        if scope.contact.country is 'United States' or scope.contact.country is '' or scope.contact.country is undefined
          return true
        else
          # clear the state if USA is not selected
          scope.contact.state = ''
          return false
      else
        return true

    # this is an initial test for keeping track of answers
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.contact}

    # adds margin-top to nav buttons directive
    scope.editState = ->
      true

    scope.$watch 'insurances', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    scope.init()
)

