intakesModule = angular.module('intakesModule')
intakesModule.directive('answerAllergies', ($log) ->
  templateUrl: "patient/intakes/questions/pre-built/allergies/a-allergies.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
  }
  link: (scope, elem, attrs) ->
    scope.allergies = scope.answers ||  []
    scope.isActive = _.filter scope.allergies, {isActive: true}
    scope.isInactive = _.filter scope.allergies, {isActive: false}

    scope.getActiveString = (isActive) ->
      if isActive
        return 'Active'
      else
        return 'Inactive'
)

