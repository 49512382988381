intakesModule = angular.module('intakesModule')
intakesModule.directive('questionContactInfo', ($log, RegionService) ->
  templateUrl: "patient/intakes/questions/pre-built/contact-info/q-contact-info.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.countries = RegionService.getCountries()
    scope.states = RegionService.getUSStates()
    scope.patient = {}

    scope.init = ->
      if scope.answer?.data
        scope.patient = scope.answer.data

    scope.phoneNumberPattern = (->
      regexp = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/
      test: (value) ->
        regexp.test value
    )()

    # decide whether to show state or not.
    scope.showState = ->
      if scope.patient.country?
        if scope.patient.country is 'United States' or scope.patient.country is '' or scope.patient.country is undefined
          return true
        else
          # clear the state if USA is not selected
          scope.patient.state = ''
          return false
      else
        return true

    # this is an initial test for keeping track of answers
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.patient}

    # adds margin-top to nav buttons directive
    scope.editState = ->
      true

    scope.$watch 'insurances', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    scope.init()
)

