# add these elements to your element to create a popover
# popover <-- instantiates the directive
# popover-label="title here"
# popover-html="content here"
# popover-placement="bottom" - optional
# popover-watch="template.forms" - optional
globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive 'intake-popover', ($rootScope, $timeout, $log) ->
  restrict: 'A'
  link: (scope, element, attrs, ctrls) ->

    element.popover({
      trigger: 'hover'
      title: attrs.popoverLabel
      content: attrs.popoverHtml
      placement: attrs.popoverPlacement || 'bottom'
      template:
        """
          <div class="popover popover-confirm-info fade bottom in">
            <div class="arrow"></div>
            <h3 class="popover-title"></h3>
            <div class="popover-content"></div>
          </div>
        """
    })

    # update the content on click
    element.bind 'click', ->
      # give angular time to update vars and then update popover
      $timeout ->
        element.attr("data-original-title", attrs.popoverLabel)
        element.attr("data-content", attrs.popoverHtml)
        element.popover('show')
      , 0

    # popoverWatch attr - watch that object and update our popover when it changes
    scope.$watch attrs.popoverWatch, (e) ->
      element.attr("data-original-title", attrs.popoverLabel)
      element.attr("data-content", attrs.popoverHtml)
    , true
