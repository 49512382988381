# don't allow more than maxlength of characters on a field: http://stackoverflow.com/questions/17075969/ng-maxlength-screws-up-my-model
globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive "pfMaxlength", ->
  require: "ngModel"
  link: (scope, element, attrs, ngModelCtrl) ->
    maxlength = Number(attrs.pfMaxlength)
    fromUser = (text) ->
      if text.length > maxlength
        # console.log 'too many characters'
        transformedInput = text.substring(0, maxlength)
        ngModelCtrl.$setViewValue transformedInput
        ngModelCtrl.$render()
        return transformedInput
      text
    ngModelCtrl.$parsers.push fromUser
    return