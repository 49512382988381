intakesModule = angular.module('intakesModule')
intakesModule.directive('answerPersonalInfo', ($log) ->
  templateUrl: "patient/intakes/questions/pre-built/personal-info/a-personal-info.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
  }
  link: (scope, elem, attrs) ->
    scope.patient = scope.answers
    scope.placeholder = "-" if !scope.answers?
)

