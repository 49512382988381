intakesModule = angular.module('intakesModule')
intakesModule.directive('questionRace', ($log, Restangular, RaceService) ->
  templateUrl: "patient/intakes/questions/pre-built/race/q-race.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.decline_option = {}
    scope.selected = []
    scope.races = []

    # Get all races
    RaceService.getAll().then (response) ->
      scope.races = response

      # add answer(s) to our selected list
      if scope.answer?.data?
        console.log scope.answer
        selected_options_ids = _.map(scope.answer.data, "optionId")

        # find our answers in the list of races returned from server
        answers = _.filter response, (race) -> _.includes(selected_options_ids, race.option_id)

        # add each answer to our selected list via the .add() method
        _.each answers, (answer) -> scope.add(answer)

      scope.decline_option = _.find(response, { "option_id": 7 })

      # start our watcher AFTER we've updated the selected list via the add() method
      # if we don't init this after, then it'll clear our existing answers before we add them to the selected list
      scope.$watch 'selected', (object) ->
        scope.$emit 'patient-answer-updated', scope.computeAnswers()

    # Add a selected race
    scope.add = (race) ->
      if !scope.containsRace(scope.selected, race)
        if race.option_id == scope.decline_option.option_id || scope.containsRace(scope.selected, scope.decline_option)
          scope.resetRaceList()

        scope.selected.push(Restangular.stripRestangular race)
        scope.races = _.without(scope.races, race)

    # Remove a selected race
    scope.remove = (race) ->
      scope.selected = _.without(scope.selected, race)
      scope.races.push(race)

    # Keep race list in order with scope.decline_option at end
    scope.getRaces = ->
      decline = _.find( scope.races, scope.decline_option )
      scope.races = _.sortBy(scope.races, "value")
      if decline?
        scope.races = _.without(scope.races, decline)
        scope.races.push(decline)
      scope.races

    # bypassing CSS error where border shows up if empty <ul> has any whitespaces in it
    scope.getBorder = ->
      "border: none" if scope.selected.length is 0

    # Helpers
    scope.containsRace = (array, race) ->
      return _.find(array, Restangular.stripRestangular race)?

    scope.resetRaceList = ->
      scope.races = _.union(scope.races, scope.selected)
      scope.selected = []

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}
)
