intakesModule = angular.module('intakesModule')
intakesModule.directive('questionPersonalInfo', ($log) ->
  templateUrl: "patient/intakes/questions/pre-built/personal-info/q-personal-info.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.patient = {}
    scope.patient.sex = 'female'

    scope.init = ->
      if scope.answer?.data
        scope.patient = scope.answer.data

    # this is an initial test for keeping track of answers
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.patient}

    # adds margin-top to nav buttons directive
    scope.editState = ->
      true

    scope.$watch 'insurances', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    scope.init()
)

