intakesModule = angular.module('intakesModule')
intakesModule.controller 'PreviewResponseController', ($scope, $state, $stateParams, $rootScope, $log, $timeout, FormResponsesService)->
  $log = $log.getInstance('PreviewResponseController')
  FormResponsesService.find($stateParams.form_response_id).then (response) ->
    $scope.form_response = response.formResponses
    $scope.question = _.filter($scope.form_response.form.components, {'id': $stateParams.question_response_id})[0]
    $scope.answer = _.filter($scope.form_response.questionResponses, {'formComponentGuid': $stateParams.question_response_id})[0]
    $log.log 'formResponse', $scope.form_response
    $log.log 'answer', $scope.answer

    # create the model for the view
    $scope.document = {}
    $scope.document.copy = $scope.question.options[0]
    $scope.document.signature = $scope.answer.data.signature
    $scope.document.date = $scope.answer.data.date