intakesModule = angular.module('intakesModule')
intakesModule.controller('AgreementController', ($rootScope, $scope, $sce, $state, $timeout, $stateParams, $resource, $log, AuthService, AgreementsService)->
  $log = $log.getInstance('AgreementController')
  $scope.environment = AuthService.getEnvironment()
  $scope.document = {}
  $scope.document.date = new Date()
  $log.log '$stateParams', $stateParams

  AgreementsService.findAll('OAB').then (response) ->
    $scope.agreement = response.data[0]
    $scope.agreementUrl = $sce.trustAsResourceUrl $scope.agreement.url

  $scope.init = ->
    $scope.header = {
      headerText: 'Please read and sign the document'
      hideQuestionCount: true
      previewMode: false
      steps: 2
      step: 1
    }

    # mimic .then() callback here w/ $timeout
    $timeout ->
      $rootScope.$emit('info-module-update', $scope.header)
    , 0

  $scope.submit = ->
    # post to the agreements endpoint to update as accepted
    isAccepted = true
    agreementType = 'OAB'
    AgreementsService.post(agreementType, isAccepted, $scope.document.signature, $stateParams.intake_id).then (response) ->
      $log.log 'submit response', response
      $state.go('intakes.show', {intake_id: $stateParams.intake_id})

  $scope.decline = ->
    # post to the agreements endpoint to update as declined
    isAccepted = false
    agreementType = 'OAB'
    AgreementsService.post(agreementType, isAccepted, null, $stateParams.intake_id).then (response) ->
      $log.log 'decline response', response
      $state.go('intakes.agreementDeclined', {intake_id: $stateParams.intake_id})

  $scope.init()
)

