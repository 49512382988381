globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive 'intakeNotification', ($rootScope, $timeout, $log) ->
  restrict: 'E'

  link: (scope, element, attrs) ->
    scope.timeout = null
    scope.message = null
    scope.show = true

    $rootScope.$on 'intake-warning', (event, data) ->
      scope.setupAndDisplay(event, data)

    $rootScope.$on 'intake-success', (event, data) ->
      scope.setupAndDisplay(event, data)

    $rootScope.$on 'intake-info', (event, data) ->
      scope.setupAndDisplay(event, data)

    scope.setupAndDisplay = (event, data) ->
      $timeout.cancel(scope.timeout)
      scope.show = true
      scope.toastType = event.name.toString()
      if data?.status
        scope.message = String(data.status)
      else
        scope.message = data
      scope.remove()

    scope.remove = ->
      scope.timeout = $timeout ->
        scope.message = null
        scope.toastType = null
      , 3000

    scope.close = ->
      scope.show = false

  template:
    """
    <div id="toast-container" class="toast-center" aria-live="polite" role="toast" role="toast" ng-if="message" ng-show="show">
        <div ng-class="{
                    'toast toast-warning fx-fade-down' : toastType == 'intake-warning',
                    'toast toast-info fx-fade-down' : toastType == 'intake-info',
                    'toast toast-success fx-fade-down' : toastType == 'intake-success',
                    }">
            <button class="toast-close-button" role="button" ng-click="close()"><i class="icon-off"></i></button>
            <div class="toast-message">{{message}}</div>
        </div>
    </div>
    """