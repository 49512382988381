intakesModule = angular.module('intakesModule')
intakesModule.controller('PreviewFormsController', ($scope, $state, $stateParams, $log)->
  $scope.answers = []

  $scope.$on 'patient-answer-updated', (event, answer) ->
    $scope.answers = _.reject $scope.answers, {'formComponentGuid': answer.formComponentGuid}
    $scope.answers.push(answer)
    # $log.info 'answer updated', answer

  # used to display (or not) different UI elements to the user depending what states are current
  $scope.isAnswerMode = ->
    return $state.current.name == 'preview.forms.answers' || $state.current.name == 'preview.forms.answer'
)

