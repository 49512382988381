intakesModule = angular.module('intakesModule')
intakesModule.controller('SubmittedController', ($rootScope, $scope, $state, $stateParams, $window, $timeout, AuthService, IntakesService, $log)->
  $log = $log.getInstance('SubmittedController')

  $scope.isAppointment = AuthService.isAppointment()
  $scope.phrHomeUrl = AuthService.getConfigData().PHR_HOME_URL
  $scope.redirectSeconds = 10
  $scope.header = {}

  IntakesService.find($stateParams.intake_id, 'appointment_intakes', AuthService.getSurveyParams()).then (response) ->
    $log.info 'SubmittedController intake', response.data
    $scope.intake = response.data
    $scope.appointment = $scope.intake.appointment
    $scope.form_response = $scope.intake.formResponse
    $scope.form = $scope.intake.formResponse.form

    # mimic .then() callback here w/ $timeout
    $timeout ->
      $rootScope.$emit('info-module-update', $scope.buildHeader())
    , 0

    # count down the remaining seconds
    # redirect to PHR after count down complete
    $scope.redirectTimeout = ->
      $timeout ->
        $scope.redirectSeconds--
        if $scope.redirectSeconds == 0
          $log.log "redirecting to PHR..."
          $window.location.href = $scope.phrHomeUrl
        else
          $scope.redirectTimeout()
      , 1000

    $scope.redirectTimeout()

  # Determine the header based on the intake type and
  # return the header object to display in the info-module
  $scope.buildHeader = ->
    if AuthService.isSurvey()
      # survey header
      $scope.header = {
        headerText: 'Thank you for submitting your information'
        steps: $scope.form.components.length
        step: 0
        previewMode: false
      }
    else if AuthService.isAppointment()
      # appointment header
      $scope.header = {
        providerProfileGuid: $scope.appointment.providerProfileGuid
        providerName: $scope.appointment.providerFirstName + " " + $scope.appointment.providerLastName
        headerText: 'Thank you for submitting your information'
        dateTime: $scope.appointment.startDateTimeUtc
        steps: $scope.form.components.length
        step: 0
        previewMode: false
      }
    return $scope.header
)

