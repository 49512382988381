/* Auth0Lock must be attached to global scope in order
   for angular-lock to access and wrap it as the Angular
   service named, 'auth0.lock'. */
import Auth0Lock from "auth0-lock";
window.Auth0Lock = Auth0Lock;
import "angular-lock";

const landingPageModule = angular.module("landingPageModule", ["auth0.lock"]);

landingPageModule.config(configureLockProvider);
landingPageModule.controller("LandingPageController", [
  "AuthService",
  "$http",
  "$location",
  "lock",
  "$log",
  "$scope",
  "UtilityService",
  function (AuthService, $http, $location, lock, $log, $scope, UtilityService) {
    $log = $log.getInstance("LandingPageController");
    $log.log("dev landing page controller loaded");

    AuthService.initializeAuthenticatedSession();

    if (UtilityService.isValidGuid($location.search().appointmentGuid))
      sessionStorage.appointmentGuid = $location.search().appointmentGuid;

    $scope.appointmentGuid = sessionStorage.appointmentGuid || "";
    $scope.hasValidAuthToken = AuthService.hasValidAuthToken();
    $scope.lock = lock;
    $scope.patient = null;
    $scope.username = sessionStorage.ehrUserName;
    $scope.password = sessionStorage.ehrPassword;

    $scope.loginProvider = function () {
      const authBaseUrl =
        "https://int.practicefusion.com/EhrAuthEndpoint/api/v3/";

      sessionStorage.ehrUserName = $scope.username;
      sessionStorage.ehrPassword = $scope.password;

      $http
        .post(authBaseUrl + "users/login", {
          loginEmailAddress: $scope.username,
          password: $scope.password,
        })
        .then(
          () => {},
          ({ data }) => {
            if (data.subcode === "BrowserNotAuthorized") {
              const {
                details: { fauxSessionToken },
              } = data;

              $http
                .post(authBaseUrl + "browser/setup", {
                  browserAuthorizationSessionToken: fauxSessionToken,
                  isSms: true,
                })
                .then(() => {
                  $http
                    .post(authBaseUrl + "browser/authorize", {
                      browserAuthorizationSessionToken: fauxSessionToken,
                      rememberBrowser: true,
                      validationCode: "12345",
                    })
                    .then(({ data }) => {
                      $scope.hasValidAuthToken = true;
                      sessionStorage.sessionToken = data.sessionToken;
                      $scope.sessionToken = data.sessionToken;
                      $scope.practiceGuid = data.practiceGuid;
                    });
                });
            }
          }
        );
    };

    $scope.onLogout = function () {
      AuthService.expireAuthToken();
      delete sessionStorage.appointmentGuid;
      window.location.reload();
    };

    $scope.onTransitionToAppointmentIntake = function () {
      if ($scope.hasValidAuthToken) {
        $location.url(
          `/patient?route=patient/appointments&appointment_guid=${$scope.appointmentGuid}`
        );
      }
    };

    $scope.onTransitionToPatientAnswers = function () {
      const route = `preview/forms/${$scope.responseId}/answers&practiceGuid=${$scope.practiceGuid}&patientPracticeGuid=${$scope.patientPracticeGuid}`;
      $location.url(
        `/provider?sessionKey=${$scope.sessionToken}&route=${route}`
      );
    };

    $scope.onTransitionToProviderIntakeForms = function () {
      $location.url(`/provider?sessionKey=${$scope.sessionToken}`);
    };

    const init = function fetchPatientInfo() {
      if (AuthService.hasValidAuthToken()) {
        AuthService.initializeAuthenticatedSession();
        /* The phr patient endpoint will automatically return the patient associated
           with the auth token utilized by the current session. */
        $http
          .get(
            AuthService.getConfigData().PHR_PATIENT_ENDPOINT_URL +
              "/phr_patients"
          )
          .then(
            function (successfulResponse) {
              $scope.patient = successfulResponse.data.data[0];
            },
            function (failureResponse) {
              $scope.patient =
                failureResponse.data || "Failed to retrieve patient info.";
            }
          );
      }
    };
    init();
  },
]);

function configureLockProvider(lockProvider) {
  const config = window.gon.config;

  lockProvider.init({
    clientID: config.PATIENT_PORTAL_AUTH0_CLIENT_ID,
    domain: config.AUTH0_DOMAIN_URL,
    options: {
      allowedConnections: ["Patient-Fusion-Users"],
      allowForgotPassword: false,
      allowSignUp: false,
      auth: {
        audience: config.AUTH0_AUDIENCE,
        responseType: "token",
        redirectUrl: config.AUTH0_REDIRECT_URL,
      },

      // additional configuration needed for custom domains
      configurationBaseUrl: "https://cdn.auth0.com",
      overrides: {
        __tenant: config.AUTH0_TENANT,
        __token_issuer: config.AUTH0_DOMAIN_URL,
      },
      theme: {
        primaryColor: "#FF7F00",
      },
    },
  });
}
