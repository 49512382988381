# /symptoms/autocomplete
intakesModule = angular.module('intakesModule')
intakesModule = angular.module('intakesModule').factory 'EthnicityService', (Restangular, AuthService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_PATIENT_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'getList'
        list = data.ethnicity_options
        return list

  _ethnicityService = restAngular.all("ethnicity_options")

  findAll: ->
    _ethnicityService.getList()