intakesModule = angular.module('intakesModule')
intakesModule.controller 'PatientController', ($scope, $location, $log, $state, AuthService, IntakesService, UtilityService) ->
  $log = $log.getInstance('PatientController')
  $log.log 'patient-controller-$location', $location.search()
  $scope.environment = AuthService.getEnvironment()
  $scope.appointment_guid = $location.search().appointment_guid
  $scope.route = $location.search().route

  AuthService.setIsPhrUser(true)
  AuthService.setRoute($scope.route)

  if UtilityService.isValidGuid($scope.appointment_guid)
    AuthService.setSubjectGuid($scope.appointment_guid)

    if AuthService.initializeAuthenticatedSession()
      AuthService.setError('')

      IntakesService.find($scope.appointment_guid, 'appointment_intakes').then (response) ->
        intake = response.data
        appointment = intake.appointment
        formResponse = intake.formResponse

        exists = appointment? is not null
        isInThePast = moment.utc(appointment?.endDateTimeUtc).isBefore()
        isCancelled = /cancel/i.test(appointment?.status)
        hasSubmittedForm = formResponse?.isSubmitted == true

        $log.log "patient-controller-appointment_guid", $scope.appointment_guid
        $log.log "patient-controller-exists", exists
        $log.log "patient-controller-isInThePast", isInThePast
        $log.log "patient-controller-isCancelled", isCancelled
        $log.log "patient-controller-hasSubmittedForm", hasSubmittedForm

        if !exists
          $state.go('patientErrors', { error: 'appointment_not_found'} )

        else if isCancelled
          $state.go('patientErrors', { error: 'appointment_cancelled'} )

        else if !hasSubmittedForm and isInThePast
          $state.go('patientErrors', { error: 'appointment_in_past'} )

        else
          $state.go('intakes.show', { 'intake_id': $scope.appointment_guid })
  else
    $state.go('patientErrors', { error: 'appointment_not_found'} )
