angular.module('formsModule').controller 'RootController', ($scope, $location, $state, $log, $window, AuthService) ->
  $log = $log.getInstance('RootController')
  $log.log('The controller for root loaded.')

  if AuthService.getEnvironment() is 'development'
    if AuthService.getConfigData().phrAuthToken
      AuthService.setAuthCookie(token)
    else
      auth0ResponseParams = $location.search()
      if auth0ResponseParams?.access_token?
        AuthService.setIsPhrUser(true)
        AuthService.setAuthCookie(auth0ResponseParams.access_token)
        AuthService.initializeAuthenticatedSession()
    $state.go('dev_landing_page', {})
  else
    $state.go('patientErrors', { error: 'session_invalid' })