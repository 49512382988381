intakesModule = angular.module('intakesModule')
intakesModule.directive('answerMedications', ($log) ->
  templateUrl: "patient/intakes/questions/pre-built/medications/a-medications.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
  }
  link: (scope, elem, attrs) ->
    scope.medications = scope.answers ||  []
    scope.isActive = _.filter scope.medications, {isActive: true}
    scope.isInactive = _.filter scope.medications, {isActive: false}

    scope.getActiveString = (isActive) ->
      if isActive
        return 'Taking'
      else
        return 'Stopped taking'
)

