intakesModule = angular.module('intakesModule')
intakesModule.controller 'QuestionController', ($scope, $state, $stateParams, $rootScope, $log, $timeout, IntakesService, FormResponsesService, AuthService) ->
  $log = $log.getInstance('QuestionController')
  $log.log '$stateParams', $stateParams
  $scope.header = {}

  # TODO: stop refetching the appointment after initial load?
  IntakesService.find($stateParams.intake_id, 'appointment_intakes', AuthService.getSurveyParams()).then (response) ->
    # since we are loading the appointment for each question - we should update the answers in the parent controller
    # this alleviates the potential for the parent controller to have no answer data if we reload the app on a question and
    # not the index page
    _.each(response.data.formResponse.questionResponses, (thisAnswer) ->
      $scope.$emit 'patient-answer-updated', thisAnswer
    )

    $log.info 'IntakeController intake', response.data
    $scope.intake = response.data
    $scope.appointment = $scope.intake.appointment
    $scope.form = $scope.intake.formResponse.form
    $scope.insurance_plans = $scope.intake.insurancePlans

    $scope.question = _.filter($scope.form.components, {'id': $stateParams.question_id})[0]
    $scope.answer = _.filter($scope.$parent.answers, {'formComponentGuid': $stateParams.question_id})[0]
    $scope.answers = $scope.$parent.answers

    # mimic .then() callback here w/ $timeout
    $timeout ->
      $rootScope.$emit('info-module-update', $scope.buildHeader())
    , 10

    # NOTE: This is a child function in the request callback because we don't want to fire an event if somehow the user
    # clicked the navigation buttons before the data was loaded.
    # ------
    # listens for clicks on continue and btn buttons and performs a PUT on the form responses to save answers to intake
    $scope.$on("patient-navigates", (event, data) ->
      $scope.intake.formResponse.questionResponses = $scope.$parent.answers
      FormResponsesService.put($scope.intake.formResponse).then (response) ->
        if data.state? && data.params?
          $state.go(data.state, data.params)
        $log.info 'answers saved'
    )

  # Determine the header based on the intake type and
  # return the header object to display in the info-module
  $scope.buildHeader = ->
    if AuthService.isSurvey()
      # survey header
      $scope.header = {
        headerText: IntakesService.getQuestionHeaderFor($scope.question.component) || $scope.question.label
        steps: $scope.form.components.length
        step: _.indexOf($scope.form.components, $scope.question) + 1
        previewMode: false
      }
    else if AuthService.isAppointment()
      # appointment header
      $scope.header = {
        providerProfileGuid: $scope.appointment.providerProfileGuid
        providerName: $scope.appointment.providerFirstName + " " + $scope.appointment.providerLastName
        headerText: IntakesService.getQuestionHeaderFor($scope.question.component) || $scope.question.label
        dateTime: $scope.appointment.startDateTimeUtc
        steps: $scope.form.components.length
        step: _.indexOf($scope.form.components, $scope.question) + 1
        previewMode: false
      }
    return $scope.header