# /symptoms/autocomplete
intakesModule = angular.module('intakesModule').factory 'BillingService', (Restangular, AuthService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().BILLING_ENDPOINT_URL)

  _payersService = restAngular.all("payers")

  autocompletePayors: (query, max_results) ->
    _payersService.customGET('autocomplete', {q: query, max_results: max_results})

  autocompletePlans: (payer_id, query, max_results) ->
    _payersService.one(payer_id).customGET('plans/autocomplete', {q: query, max_results: max_results})

  findPlansForPayor: (payor_guid) ->
    _payersService.one(payor_guid).customGET('plans');