intakesModule = angular.module('intakesModule')
intakesModule.directive('questionMedications', ($log, MedicationsService) ->
  templateUrl: "patient/intakes/questions/pre-built/medications/q-medications.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.selected = []
    scope.isActive = true
    scope.maxResults = 10

    scope.init = ->
      if scope.answer?.data
        scope.selected = scope.answer.data

    # get autocomplete medications list for query
    scope.get = (query) ->
      MedicationsService.autocomplete(query, scope.maxResults).then (response) ->
        # remove responses that have the value already in the selected list
        return _.reject(response.drugs, (medication) ->
          return _.includes(_.map(scope.selected, 'value'), medication.value)
        )

    # add an medication from the autocomplete list OR add a custom medication
    scope.add = (medication, isCustomValue) ->
      if isCustomValue
        medication = {
          name: medication,
          value: medication,
          isActive: scope.isActive,
          preExisting: false
        }
      else
        medication.isActive = scope.isActive

      # add the item to the selected list if it's not a duplicate
      if !_.isEmpty(medication.name)
        scope.$emit 'close-typeahead-popup'
        scope.selected.push(medication) if !_.includes(_.map(scope.selected, 'name'), medication.name)

    scope.remove = (medication) ->
      scope.selected = _.without scope.selected, medication

    # this is an initial test for keeping track of answers
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}

    scope.$watch 'selected.length', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    scope.init()
)

