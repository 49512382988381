intakesModule = angular.module('intakesModule')
intakesModule.controller('AgreementDeclinedController', ($rootScope, $scope, $state, $timeout, $stateParams, $log, $window, AuthService) ->
  $log = $log.getInstance('AgreementDeclinedController')
  $scope.environment = AuthService.getEnvironment()
  $scope.redirectUrl = AuthService.getConfigData()?.PHR_HOME_URL
  $scope.redirectSeconds = 10

  $scope.init = ->
    $scope.redirectTimeout()
    $scope.header = {
      headerText: 'You denied the survey authorization'
      hideQuestionCount: true
      previewMode: false
      steps: 2
      step: 2
    }

    # mimic .then() callback here w/ $timeout
    $timeout ->
      $rootScope.$emit('info-module-update', $scope.header)
    , 0

  $scope.redirectTimeout = ->
    $timeout ->
      $scope.redirectSeconds--
      if $scope.redirectSeconds == 0
        $log.log "redirecting..."
        $window.location.href = $scope.redirectUrl
      else
        $scope.redirectTimeout()
    , 1000

  $scope.init()
)

