intakesModule = angular.module('globalHelpersModule')
intakesModule.directive('infoBar', ($log) ->
  restrict: 'E',
  transclude: true
  link: (scope, element, attrs) ->

  templateUrl: "global/info-bar/info-bar-directive-template.html"

)

