# /symptoms/autocomplete
intakesModule = angular.module('intakesModule')
intakesModule = angular.module('intakesModule').factory 'SmokingService', (Restangular, AuthService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'getList'
        list = data.smoking_statuses
        return list

  _smokingService = restAngular.all("smoking_statuses")

  findAll: ->
    _smokingService.getList()

  getCommon: ->
    [
      {
        "optionId": 1,
        "value": "Never smoker",
        "isActive": true
      },
      {
        "optionId": 2,
        "value": "Former smoker",
        "isActive": true
      },
      {
        "optionId": 3,
        "value": "Light tobacco smoker",
        "isActive": true
      },
      {
        "optionId": 4,
        "value": "Current some day smoker",
        "isActive": true
      },
      {
        "optionId": 5,
        "value": "Current everyday smoker",
        "isActive": true
      },
      {
        "optionId": 6,
        "value": "Heavy tobacco smoker",
        "isActive": true
      }
    ]