###*
# @ngdoc directive
# @name globalHelpersModule.directive:dateField
# @restrict A
# @scope
# @description
# Preface: Consumer team expects the user to enter date values into an input field as MM/DD/YYYY
# Our apis send and receive date data in ISO 8601 format http://www.w3schools.com/jsref/jsref_toisostring.asp
# This directive when applied as an attribute to an input[type='text'] will format the input field to accept the MM/DD/YYYY format
# and convert that to ISO 8601 under the covers for our model.
# The priority of 0 makes sure the post-link function fires last when multiple directives are used in a chain.
# This directive is specifically coded to work in conjunction with our custom ui-mask directive.
# For more info on ngModelController, $formatters, and $parsers see: https://code.angularjs.org/1.2.29/docs/api/ng/type/ngModel.NgModelController
#
# @example
    <pre>
      <input type="text"
        date-field
        ng-model="patient.dateOfBirth"
        id="date-of-birth"
        name="dateOfBirth"
        data-element="date-of-birth"
        placeholder="Date of birth (MM/DD/YYYY)"
        ui-mask="99/99/9999"
        ui-mask-placeholder="MM/DD/YYYY" />
    </pre>
###
angular.module('globalHelpersModule').directive "dateField", ($filter) ->
  priority: 0
  require: "ngModel"
  restrict: "A"
  scope: {}
  link: (scope, element, attrs, ngModelController) ->
    # Model -> View transformation
    ngModelController.$formatters.unshift (modelValue) ->
      $filter("date") modelValue, "MM/dd/yyyy"

    # View -> Model transformation
    ngModelController.$parsers.unshift (viewValue) ->

      isValidDate = (value) ->
        # http://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
        # takes MM/DD/YYYY and detects if the date is a valid date (for example 01/33/1979 is invalid)
        # honors leap years
        /((^(10|12|0?[13578])([/])(3[01]|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(11|0?[469])([/])(30|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(2[0-8]|1[0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(29)([/])([2468][048]00)$)|(^(0?2)([/])(29)([/])([3579][26]00)$)|(^(0?2)([/])(29)([/])([1][89][0][48])$)|(^(0?2)([/])(29)([/])([2-9][0-9][0][48])$)|(^(0?2)([/])(29)([/])([1][89][2468][048])$)|(^(0?2)([/])(29)([/])([2-9][0-9][2468][048])$)|(^(0?2)([/])(29)([/])([1][89][13579][26])$)|(^(0?2)([/])(29)([/])([2-9][0-9][13579][26])$))/.test value

      if _.isEmpty viewValue
        date = null
      else
        # put back the /'s in MMDDYYYY -> MM/DD/YYYY
        value = viewValue.replace(/(\d{2})(\d{2})(\d{4})/g, '$1/$2/$3')
        if isValidDate(value)
          date = new Date(value).toISOString()

      date