formsModule = angular.module('formsModule')
formsModule.controller 'FormsController', ($builder, $scope, $state, $stateParams, $log, FormService, AuthService, UtilityService) ->
  $log = $log.getInstance('FormsController');
  $log.log('FormsController loaded');

  $scope.environment = AuthService.getEnvironment()

  # PHR-4886: hide any open popups that are orphaned from the forms.show state
  $(".popover").popover('hide')

  $scope.newForm = ->
    FormService.create({title: "test"})

  $scope.destroy = ->
    # http://stackoverflow.com/questions/18523806/deleting-entry-with-restangular
    angular.forEach $scope.forms, (value, key) ->
      # only allow DELETE if providerGuid's match (value.providerGuid == AuthService.getProviderGuid())
      if value.selected is true and !value.isActive and (UtilityService.compareGuids(value.providerGuid, AuthService.getProviderGuid()) or AuthService.isAdministrator())
        # perform a DELETE
        value.remove({id: value.id}).then () ->
          # update the UI
          $scope.forms = _.without $scope.forms, value