formsModule = angular.module('formsModule')
formsModule.directive 'filterTable', ($log, AuthService, UtilityService, FormService) ->
  restrict: 'E'

  link: (scope, element) ->
    scope.selectAllToggle = false
    scope.pageNumber = 1
    scope.pageSize = 25
    scope.isScrolling = false # setting to true disables scrolling
    scope.isLoading = false # shows the loading indicator

    # we want to load all the owned forms 'practice'. setting load # to 1000
    FormService.findAll('practice', 1, 1000).then (response) ->
      scope.forms = response

    FormService.findAll('shared_by_pf').then (response) ->
      scope.pfForms = response

    FormService.findAll('shared_by_community', scope.pageNumber, scope.pageSize).then (response) ->
      scope.communityForms = _.union scope.communityForms, response

    scope.addCommunityForms = ->
      scope.pageNumber++
      scope.isScrolling = true
      scope.isLoading = true
      FormService.findAll('shared_by_community', scope.pageNumber, scope.pageSize).then (response) ->
        scope.communityForms = _.union scope.communityForms, response
        # turn off scrolling calls permanently when we receive an empty array (no more forms to load)
        scope.isScrolling = false if response.length isnt 0
        scope.isLoading = false

    # uncheck selectAllToggle if all forms are deleted
    scope.$watch 'forms.length', (newValue, oldValue) ->
      scope.selectAllToggle = false if newValue < 1

    # toggle all the checkboxes to state of selectAllToggle checkbox
    scope.toggleSelected = ->
      angular.forEach scope.forms, (form, key) ->
        form.selected = !scope.selectAllToggle unless form.isActive || !scope.formOwner(form)

    # is this form owned by the currently logged in user?
    scope.formOwner = (form) ->
      return UtilityService.compareGuids(form.providerGuid, AuthService.getProviderGuid()) || AuthService.isAdministrator()

    scope.getIsSharedString = (form) ->
      return 'Not shared' if !form.isShared and scope.formOwner(form)

    # only return active label if the form isActive, the service ensures only one form is active
    scope.getIsDefaultString = (form) ->
      return 'Default' if form.isActive

  templateUrl: "provider/forms/forms-table-directive-template.html"
