intakesModule = angular.module('intakesModule')
intakesModule.directive('questionSmoking', ($rootScope, $log, $http, $filter, SmokingService, Restangular) ->
  templateUrl: "patient/intakes/questions/pre-built/smoking/q-smoking.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.smoking_statuses = null
    scope.selected = null

    SmokingService.findAll().then (response) ->
      scope.common = Restangular.stripRestangular response
      scope.commonCache = scope.common
      scope.init()

    scope.init = ->
      # assign the answer if it exists
      if scope.answer?.data?
        scope.selected = scope.answer.data
        # assign initial common smoking statuses
        scope.common = $filter('filter')(scope.commonCache, {name: '!'+scope.selected.name}) # ! operator does a 'without' operation

    # add selected smoking
    scope.add = (smoking) ->
      scope.selected = smoking
      scope.common = $filter('filter')(scope.commonCache, {name: '!'+scope.selected.name})

    # remove selected smoking
    scope.remove = (smoking) ->
      scope.selected = null
      scope.common = scope.commonCache

    # bypassing CSS error where border shows up if empty <ul> has any whitespaces in it
    scope.getBorder = ->
      "border: none" if scope.selected is null

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}

    scope.$watch 'selected', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()
)

