window.intake.config(['$locationProvider', '$stateProvider', '$urlRouterProvider',
  ($locationProvider, $stateProvider, $urlRouterProvider) ->
    # NOTE: make sure ALL routes end with a '/' forward slash:
    # https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-make-a-trailing-slash-optional-for-all-routes

    # Auth takes place here
    $stateProvider.state('root', {
      url: '/',
      templateUrl: 'root/index.html'
      controller: 'RootController'
      data: {
        pageTitle: ''
      }
    })

    $stateProvider.state('dev_landing_page', {
      url: '/dev_landing_page/',
      templateUrl: 'landing-page/index.html',
      controller: 'LandingPageController',
      data: {
        pageTitle: 'Dev Landing Page'
      }
    })
    
    # Provider routing
    $stateProvider.state('provider', {
      url: '/provider/'
      templateUrl: 'provider/index.html'
      controller: 'ProviderController'
      data: {
        appState: 'provider' # used for authenticating a user type to a section of the app
        pageTitle: 'Intake templates'
      }
    }).state('provider.forms', {
      url: 'forms/'
      templateUrl: 'provider/forms/forms-index.html'
      controller: 'FormsController'
    }).state('provider.form', {
      url: 'forms/:id/'
      templateUrl: 'provider/forms/form-show.html'
      controller: 'FormController'
      params: {'is_copy'}
    }).state('provider.errors', {
      url: 'errors/'
      templateUrl: 'provider/errors/errors-index.html'
      controller: 'ProviderErrorsController'
      params: {'message'}
    })

    # Preview root view for provider previewing the patient side of intake
    # NOTE: the appState is still 'provider'
    $stateProvider.state('preview', {
      abstract: true
      url: '/preview/'
      templateUrl: 'preview/preview-index.html'
      controller: 'PreviewController'
      data: {
        appState: 'provider' # used for authenticating a user type to a section of the app
        pageTitle: 'Intake template preview'
      }
    }).state('preview.forms', {
      abstract: true
      url: 'forms/'
      templateUrl: 'preview/forms/forms-index.html'
      controller: 'PreviewFormsController'
    # Provider can view a form while he/she is building it and interact with it, but not submit
    }).state('preview.forms.show', { # /#/preview/forms/:form_id/
      url: ':form_id/'
      templateUrl: 'preview/forms/form-show.html'
      controller: 'PreviewFormController'
    # Provider can view the patient's answers for an intake form
    }).state('preview.forms.answers', {
      url: ':form_response_id/answers/'
      templateUrl: 'preview/forms/form-answers-show.html'
      controller: 'PreviewAnswersController'
    # Provider can view a specific patient answer and print it (patient signature only)
    }).state('preview.forms.answer', {
      url: ':form_response_id/answers/:question_response_id/'
      templateUrl: 'preview/forms/responses/question-response-show.html'
      controller: 'PreviewResponseController'
    # Provider can view a form question
    }).state('preview.forms.question', {
      url: ':form_id/questions/:question_id/'
      templateUrl: 'patient/intakes/questions/question-show.html' # re-using patient template here
      controller: 'PreviewFormQuestionController'
    })

    # Appointment routing
    $stateProvider.state('appointment', {
      url: '/appointments/:appointmentGuid'
      controller: 'AppointmentController',
      data: {
        pageTitle: 'Check-in for appointment'
      },
      resolve: {
        appointmentGuid: ['$stateParams', ($stateParams) -> return $stateParams.appointmentGuid]
      }
    })

    # Patient routing
    $stateProvider.state('patient', {
      url: '/patient/'
      controller: 'PatientController',
      data: {
        appState: 'phr_user',
        pageTitle: 'Check-in for appointment'
      }
    })

    # Patient intakes routing
    $stateProvider.state('intakes', {
      abstract: true
      url: '/patient/intakes/'
      templateUrl: 'patient/intakes/intakes-index.html'
      controller: 'IntakesController'
      data: {
        appState: 'phr_user' # used for authenticating a user type to a section of the app
        pageTitle: 'Check-in for appointment'
      }

    # Agreements flow
    }).state('intakes.agreement', {
      url: ':intake_id/agreement'
      templateUrl: 'patient/intakes/agreements/agreement-show.html'
      controller: 'AgreementController'
    }).state('intakes.agreementDeclined', {
      url: ':intake_id/agreement/declined'
      templateUrl: 'patient/intakes/agreements/agreement-declined.html'
      controller: 'AgreementDeclinedController'

    # Regular intake flow
    }).state('intakes.show', {
      url: ':intake_id/'
      templateUrl: 'patient/intakes/intake-show.html'
      controller: 'IntakeController'
    }).state('intakes.question', {
      url: ':intake_id/questions/:question_id/'
      templateUrl: 'patient/intakes/questions/question-show.html'
      controller: 'QuestionController'
    }).state('intakes.submitted', {
      url: ':intake_id/submitted/'
      templateUrl: 'patient/intakes/submitted.html'
      controller: 'SubmittedController'
    }).state('patientErrors', {
      url: '/patient/errors/'
      templateUrl: 'patient/errors/errors-index.html'
      controller: 'PatientErrorsController'
      params: {'error'}
      data: { appState: 'phr_user' }
    })

    # Obsolete routes. Defined only so they can be redirected to an appropriate error route.
    $stateProvider.state('surveys', {
      url: '/surveys/:survey_guid/'
      data: {
        pageTitle: 'Surveys'
      }
    })
    $stateProvider.state('patient_surveys', {
      url: '/patient/surveys/:survey_guid/'
      data: {
        pageTitle: 'Patient Surveys'
      }
    })

    # For any unmatched url, redirect to /home
    $urlRouterProvider.otherwise('/')

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    })


    # https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-make-a-trailing-slash-optional-for-all-routes
    # creates a rule on $urlRouterProvider that maps all urls that are missing a
    # trailing slash to the same url but with the trailing slash appended.
    $urlRouterProvider.rule ($injector, $location) ->
      path = $location.url()

      # check to see if the path already has a slash where it should be
      if (path[path.length - 1] == '/' || path.indexOf('/?') > -1)
        return

      if (path.indexOf('?') > -1)
        return path.replace('?', '/?')

      if (path.indexOf('/access_token') > -1)
        return path.replace('/access_token', '/?access_token')

      return path + '/'
])
