intakesModule = angular.module('intakesModule')
intakesModule.directive('navigationButtons', ($rootScope, $state, $stateParams, $log) ->
  templateUrl: "patient/intakes/questions/navigation-buttons/navigation-buttons.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    $log.log "form", scope.form
    $log.log "$stateParams", $stateParams
    scope.form_id = $stateParams.form_id
    scope.intake_id = $stateParams.intake_id
    scope.isPreviewMode = $state.current.name == "preview.forms.question" ? true : false
    scope.nextQuestionIndex = (_.indexOf scope.form.components, scope.question) + 1
    scope.nextQuestionId = scope.form.components[scope.nextQuestionIndex]?.id || false
    scope.previousQuestionId = scope.form.components[scope.nextQuestionIndex - 2]?.id || false

    scope.isFirstQuestion = ->
      return scope.nextQuestionIndex == 1

    scope.isLastQuestion = ->
      return (scope.nextQuestionIndex + 1) > scope.form.components.length

    # send a patient navigation event when a patient goes to a new question
    # this event will trigger a PUT to save the current answers as the patient goes through the form
    scope.sendNavigationEvent = (state, params) ->
      scope.$emit("patient-navigates", {state: state, params: params}) #if !scope.isPreviewMode
      $log.info 'Navigate to -> ' + scope.question.component

    # should we add padding to the buttons
    # add an editState() method to the parent controller and return true or false here
    scope.editState = ->
      scope.$parent.editState?()
)