angular.module('globalHelpersModule').factory 'EhrTokenService', ($http, AuthService, $log) ->
    $log = $log.getInstance('EhrTokenService')

    find: (authCookie) ->
        url = AuthService.getConfigData().EHR_TOKEN_ENDPOINT_URL + '/token'

        if AuthService.getEnvironment() == 'development'
            $http.get(url, {
                headers: { 
                    Authorization: authCookie 
                }
            })
        else
            $http.get(url + '?authCookie=' + authCookie, { withCredentials: true })
