angular.module('globalHelpersModule').factory 'PhrTokenService', (Restangular, AuthService, $log, $state) ->
    $log = $log.getInstance('PhrTokenService')

    restAngular = Restangular.withConfig (Configurer) ->
        environment = AuthService.getEnvironment()
        isDevelopment = environment == 'development'
        isTest = environment == 'test'

        # configure the Restangular object here
        Configurer.setBaseUrl(AuthService.getConfigData().PHR_TOKEN_ENDPOINT_URL)
        Configurer.setErrorInterceptor (response) ->
            # this also lives in app.run.coffee - but these don't cascade so adding here as well
            # for PhrUser: auth credentials are bad (session timeout) go to intake login page
            if response.status == 401
                AuthService.expireAuthToken()
                if (isDevelopment || isTest)
                    $state.go('dev_landing_page', {})
                else
                    $state.go('patientErrors', { error: 'session_timeout' })

    _phrTokenService = restAngular.all("")

    find: (ephemeralToken) ->
        _phrTokenService.one('token').get(null, { Authorization: 'Bearer ' + ephemeralToken })
