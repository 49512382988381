intakesModule = angular.module('intakesModule')
intakesModule.directive('questionLanguage', ($rootScope, $log, $filter, $http, LanguagesService) ->
  templateUrl: "patient/intakes/questions/pre-built/language/q-language.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.selected = null
    scope.maxResults = 8
    scope.existingAnswer = scope.answer # losing scope.answer within the init() closure. assigning to a global var here.

    # assign the answer if it exists
    scope.init = ->
      LanguagesService.findAll().then (response) ->
        scope.languages = response
        scope.common = LanguagesService.getCommon()

        # assign the answer if it exists
        if scope.existingAnswer?.data?
          scope.selected = scope.existingAnswer.data
          # assign initial common ethnicities
          scope.common = $filter('filter')(LanguagesService.getCommon(), {value: '!'+scope.selected.value}) # ! operator does a 'without' operation

    # add selected language
    scope.add = (language) ->
      scope.selected = language
      scope.common = $filter('filter')(LanguagesService.getCommon(), {value: '!'+scope.selected.value})

    # remove selected language
    scope.remove = (language) ->
      scope.selected = null
      scope.common = LanguagesService.getCommon()

    # bypassing CSS error where border shows up if empty <ul> has any whitespaces in it
    scope.getBorder = ->
      "border: none" if !scope.selected

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}

    scope.$watch 'selected', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    scope.init()
)

