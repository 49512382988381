formsModule = angular.module('formsModule')
formsModule.controller('FormController', ($scope, $window, $timeout, $state, $stateParams, $log, $builder,
                                          FormService, AuthService, UtilityService, ModalService, NotificationService)->
  $log = $log.getInstance('FormController')
  $scope.environment = AuthService.getEnvironment()

  $scope.formCanvasHeight = '900px'
  $scope.isNewTemplate = false
  $scope.template = {}
  $builder.forms['default'] = [] # make sure the $builder is empty

  # loading a template form that was a copy? show a success message
  if $stateParams.is_copy == "true"
    NotificationService.emit('intake-success', 'Form copy is created')

  # initialize the controller with the requested form or create a new one
  $scope.findOrNew = ->
    if $stateParams.id is 'new'
      $scope.isNewTemplate = true
      $scope.isUserTemplateOwner = true
      $scope.template = FormService.new()
      # used in tracking changes to the model
      $scope.templateCache = angular.copy($scope.template)
      $scope.init()
    else
      $scope.isNewTemplate = false
      FormService.find($stateParams.id).then (response) ->
        $scope.template = response
        isOwner = UtilityService.compareGuids($scope.template.forms.providerGuid, AuthService.getProviderGuid()) || AuthService.isAdministrator()
        isInPractice = UtilityService.compareGuids($scope.template.forms.practiceGuid, AuthService.getPracticeGuid())

        if isOwner && isInPractice
          $scope.isUserTemplateOwner = true
        else
          $scope.isUserTemplateOwner = false
          # scrim disables drag and drop - making sure it's the height of the whole editable section
          $timeout ->
            $('.scrim').height($('body').height())
          , 0
          $log.info "This form is owned by another user"

        # used in tracking changes to the model
        $scope.templateCache = angular.copy($scope.template)

        # initialize our controller
        $scope.init()

  $scope.init = ->
    # for the title in the tab
    $scope.truncatedFormTitle = UtilityService.truncate($scope.template.forms.title, 32)

    # place edit popups on left
    $builder.config.popoverPlacement = 'left'
    $scope.form = $scope.template.forms

    # add our template components to the $builder on initialization
    angular.forEach $scope.template.forms.components, (component) ->
      $builder.addFormObject 'default', component

    # build our form object by binding the form builder to the form.components node
    $scope.form.components = $builder.forms['default'] # make sure we can reference from the view

    # set the form canvas (drop zone) height dynamically
    $scope.$watch 'form.components.length', ->
      # using timeout to allow the screen to draw the elements we will iterate through before we iterate
      # using jquery here is not ideal, but this is a one time use case and so it's acceptable to not include this in a directive
      $timeout ->
        computedCanvasHeight = 0
        $questions = $(".form-canvas .question")
        # iterate through the questions in the drop zone and figure out their height
        $questions.each ->
          computedCanvasHeight += $(this).height()
        computedCanvasHeight += ($questions.length * 30) # adjust for padding
        computedCanvasHeight += 100 # add space for dropping element at end
        # $log.log "computedCanvasHeight", computedCanvasHeight
        $scope.formCanvasHeight = computedCanvasHeight
        $scope.formCanvasHeight = 900 if $scope.formCanvasHeight < 900
        $scope.formCanvasHeight = $scope.formCanvasHeight + 'px'
      , 10

  # toggle form published
  $scope.share = ->
    $scope.template.forms.isShared = !$scope.template.forms.isShared
    $scope.save(false, true, true)

    # display the notification
    messageText = 'Form is shared now' if $scope.template.forms.isShared
    messageText = 'Form is unshared now' if !$scope.template.forms.isShared
    NotificationService.emit('intake-success', messageText)

  $scope.preview = ->
    if $scope.isUserTemplateOwner
      $scope.save(true, false, true)
    else
      $scope.launchPreview()

  # for new forms do a POST and redirect to new form url upon successful create
  # for existing forms do a PUT
  $scope.save = (showPreviewAfterSave=false, shareUpdated=false, showNotification=true) ->
    promise = null
    if $stateParams.id is 'new'
      promise = $scope.template.post().then (response) ->
        $log.log response
        # redirect to the new form once it's created
        $state.go('provider.form', {id: response.forms.id})
        $scope.launchPreview(response.forms.id) if showPreviewAfterSave
    else
      promise = $scope.template.put().then (response) ->
        # used in tracking changes to the model
        $scope.templateCache = angular.copy(response)
        $scope.launchPreview(response.forms.id) if showPreviewAfterSave

    # display notification if not preview and not shared
    messageText = 'Form is saved now (it is shared)' if $scope.template.forms.isShared
    messageText = 'Form is saved now (it is not shared)' if !$scope.template.forms.isShared
    NotificationService.emit('intake-success', messageText) if !shareUpdated && !showPreviewAfterSave && showNotification

    # update the title in the tab
    $scope.truncatedFormTitle = UtilityService.truncate($scope.template.forms.title, 32)

    # return the promise so we can chain
    return promise


  # open a new browser tab and launch the preview
  # form_id: used when the current user is viewing their form, otherwise we use $scope.form.id
  $scope.launchPreview = (form_id) ->
    if $scope.isUserTemplateOwner
      formId = form_id
    else
      formId = $scope.form.id

    $log.log "launching preview - form id", formId
    route = $state.href('preview.forms.show', { form_id: formId })
    url = AuthService.getConfigData().EHR_INTAKE_URL +
      '/provider?route=' +
      window.encodeURIComponent(route) + 
      '&sessionKey=' + 
      AuthService.getSessionKey()

    $window.open(url, '_blank')
    return true # must return true here because of coffeescript: https://github.com/angular/angular.js/issues/4853

  $scope.makeACopy = ->
    modalOptions =
      closeButtonText: "Cancel"
      actionButtonText: "Save & Make a copy"
      headerText: "Make a copy"
      bodyText: ""

    ModalService.showModal({templateUrl: "provider/forms/modals/_make-a-copy-modal.html"}, modalOptions).then (result) ->
      $log.log "modal form result", result
      # if the form is owned by the current user, save it first and then create and save the copy, otherwise just save the copy
      if $scope.isUserTemplateOwner && $scope.isTemplateDirty()
        $scope.save(false, false, false).then (response) ->
          buildAndSaveFormCopy(result)
      else
        buildAndSaveFormCopy(result)

    buildAndSaveFormCopy = (result) ->
      # build a new form to copy components into
      $scope.newTemplate = FormService.new()
      # give it the title the user chose in the modal
      $scope.newTemplate.forms.title = result.title
      # add the existing template components into the new form
      $scope.newTemplate.forms.components = $scope.template.forms.components
      # save the new form and redirect
      $scope.newTemplate.post().then (response) ->
        $log.log response
        # redirect to the new form once it's created
        $state.go('provider.form', {id: response.forms.id, is_copy: "true"})

  # ng-change on isActive fires this automatically.
  # PATCH sets current form as the active one for a provider
  $scope.setActive = ->
    $scope.template.put().then (response) ->
      if response.forms.isActive
        messageText = 'Form is now default and will be sent to all patients with confirmed appointments'
        NotificationService.emit('intake-success', messageText)
      else
        modalOptions =
          closeButtonText: ""
          actionButtonText: "Ok"
          headerText: "Form is no longer default"
          bodyText: "To make sure your patients continue to receive intake forms, we have set Practice Fusion's intake form to default." +
            " You can change that by marking some other form of your choice as the default."

        ModalService.showModal({templateUrl: "provider/forms/modals/_template-inactive-modal.html"}, modalOptions).then (result) ->
          # do nothing

  $scope.closeTab = ->
    if $scope.isUserTemplateOwner && $scope.isTemplateDirty()
      saveText = if $scope.isSaveDisabled() then "" else "Save"
      modalOptions =
        closeButtonText: "Discard changes"
        actionButtonText: saveText
        headerText: "Save before closing"
        bodyText: "If you close before saving, all your changes will be lost."

      ModalService.showModal({templateUrl: "provider/forms/modals/_close-tab-modal.html"}, modalOptions).then (result) ->
        $log.log "modal form result", result
        if result == "ok"
          $scope.save().then (response) ->
            $state.go('provider.forms')
        else if result == "close"
          $state.go('provider.forms')
    else
      $state.go('provider.forms')

  $scope.isSaveDisabled = ->
    return $scope.templateForm.$invalid || !$scope.template.forms.components.length > 0

  # has the form been changed from the state in which it was loaded?
  $scope.isTemplateDirty = ->
    # just compare the form root key data, disregard updatedAt and components array
    templateCache =  _.omit $scope.templateCache.forms, ["updatedAt", "components"]
    template = _.omit $scope.template.forms, ["updatedAt", "components"]
    formsEqual = angular.equals(templateCache, template)
    # $log.log $scope.templateCache.forms, $scope.template.forms
    # $log.log templateCache, template
    # just compare the components, disregard id and $$hash keys
    cachedComponents = _.map $scope.templateCache.forms.components, (o) -> _.omit o, ["$$hash", "id"]
    actualComponents = _.map $scope.template.forms.components, (o) -> _.omit o, ["$$hash", "id"]
    # $log.log cachedComponents, actualComponents
    componentsEqual = angular.equals(cachedComponents, actualComponents)
    $log.log 'forms equal?', formsEqual, 'components equal?', componentsEqual

    if componentsEqual && formsEqual
      return false
    else
      return true

  $scope.getPopoverTitle = (popoverType) ->
    if popoverType == 'share'
      return 'Share form' if !$scope.template.forms.isShared
      return 'Unshare form' if $scope.template.forms.isShared
    if popoverType == 'save'
      return 'Save'

  $scope.getPopoverContent = (popoverType) ->
    if popoverType == 'share'
      return 'By sharing this form you are sharing it with the Practice Fusion community. Anybody using Practice Fusion will be able to view and use this form. Please make sure there is no patient information in this form before sharing. You may unshare this form at any time, however, if you unshare this form it does not remove copies that other Practice Fusion users may have made.' if !$scope.template.forms.isShared
      return 'Unshare form if you do not want other Practice Fusion users to see and use your form and updates to it.' if $scope.template.forms.isShared
    if popoverType == 'save'
      return 'Save updates to your shared form' if $scope.template.forms.isShared
      return 'Save updates to your unshared form' if !$scope.template.forms.isShared

  # initialize the controller with the requested form or create a new one
  $scope.findOrNew()
)
