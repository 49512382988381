import logo from 'images/patient/intakes/header/logo.png';

intakesModule = angular.module('intakesModule')
intakesModule.directive('intakesHeader', ($log) ->
  restrict: 'E',
  link: (scope, element, attrs) ->
    scope.logo = logo
  templateUrl: "patient/intakes/header/header-directive-template.html"

)

