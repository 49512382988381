# /symptoms/autocomplete
intakesModule = angular.module('intakesModule').factory 'AllergiesService', (Restangular, AuthService, BreakpointService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'get'
        # perform a string truncate on strings with more than 48 characters
        characters = BreakpointService.truncateAt()
        _.each data.allergies, (allergy) ->
          allergy.name = allergy.name.substring(0, characters) + "..." if allergy.name.length > characters
        return data

  _formService = restAngular.all("autocomplete/allergies")

  autocomplete: (query, maxResults) ->
    _formService.customGET('', {q: query, max_results: maxResults})
