# for this directive to work there must
# be a label directive preceding the <input>

globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive 'validityIcons', ($log, $timeout, validityIconsConfig) ->
  getTrigger = (options) ->
    trigger = validityIconsConfig.trigger
    if options && options.trigger?
      trigger = options.trigger
    trigger

  getShowSuccess = (options) ->
    showSuccess = validityIconsConfig.showSuccess
    if options && options.showSuccess?
      showSuccess = options.showSuccess
    showSuccess

  linkFn = (scope, el, attrs, formCtrl) ->
    blurred = false
    options = scope.$eval attrs.validityIcons
    trigger = getTrigger options
    showSuccess = getShowSuccess options

    inputNgEl = angular.element el
    inputName = inputNgEl.attr 'name'

    validIcon = $('<i class="fa fa-2x fa-check form-control-feedback" style="bottom:-20px; margin-top:10px; right:18px; color:green; display:none;"></i>')
    invalidIcon = $('<i class="fa fa-2x fa-close form-control-feedback" style="bottom:-20px; margin-top:10px; right:18px; color:#a94442; display:none;"></i>')
    inputNgEl.before(validIcon, invalidIcon)

    inputNgEl.bind trigger, ->
      blurred = true
      toggleIcons formCtrl[inputName].$invalid

    scope.$watch ->
      formCtrl[inputName] && formCtrl[inputName].$invalid
    , (invalid) ->
      return if !blurred
      toggleIcons invalid

    scope.$on 'show-errors-check-validity', ->
      toggleIcons formCtrl[inputName].$invalid

    scope.$on 'show-errors-reset', ->
      $timeout ->
        # want to run this after the current digest cycle
        invalidIcon.hide()
        validIcon.hide()
        blurred = false
      , 0, false

    toggleIcons = (invalid) ->
      inputBlank = (inputNgEl.val() is "")
      # $log.info 'invalid:', invalid, 'blank:', inputBlank
      if inputBlank
        invalidIcon.hide()
        validIcon.hide() if showSuccess
      else
        if invalid
          invalidIcon.show()
          validIcon.hide() if showSuccess
        else if !invalid
          invalidIcon.hide()
          validIcon.show() if showSuccess
  {
    restrict: 'A'
    require: '^form'
    compile: (elem, attrs) ->
      unless elem.hasClass 'form-control'
        throw "validity-icons element does not have the 'form-control' class"
      linkFn
  }

globalHelpersModule.provider 'validityIconsConfig', ->
  _trigger = 'blur'
  _showSuccess = false

  @trigger = (trigger) ->
    _trigger = trigger

  @showSuccess = (showSuccess) ->
    _showSuccess = showSuccess

  @$get = ->
    trigger: _trigger
    showSuccess: _showSuccess

  return