intakesModule = angular.module('intakesModule')
intakesModule.directive('questionDropdown', ($rootScope, $log) ->
  templateUrl: "patient/intakes/questions/custom/dropdown/q-dropdown.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.options = scope.question.options
    scope.dropdown = ""

    # assign the answer if it exists
    if scope.answer?.data?
      scope.dropdown = scope.answer.data

    # setting to true adds margin top to nav buttons
    scope.editState = ->
      return true

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.dropdown}

    scope.$watch 'dropdown', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

)

