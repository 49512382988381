angular.module('intakesModule').controller('AppointmentController', [
    'AuthService', 'appointmentGuid',
    function(AuthService, appointmentGuid) {
        const intakeUrl = AuthService.getConfigData().INTAKE_LOGIN_URL;
        const environment = AuthService.getEnvironment();

        if (environment === 'development' || environment === 'test') {
            window.location = intakeUrl +
                '/dev_landing_page?appointmentGuid=' +
                appointmentGuid;
        } else {
            const signupUrl = AuthService.getConfigData().PF_LOGIN_URL;
            const redirectUrl = intakeUrl +
                '/patient?appointment_guid=' +
                appointmentGuid;
            
            window.location = signupUrl + 
                '?redirect=' +
                window.encodeURIComponent(redirectUrl);
            }
    }
]);