intakesModule = angular.module('intakesModule')
intakesModule.directive('questionEthnicity', ($rootScope, $log, $http, $filter, Restangular, EthnicityService) ->
  templateUrl: "patient/intakes/questions/pre-built/ethnicity/q-ethnicity.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.ethnicities = null
    scope.selected = null

    EthnicityService.findAll().then (response) ->
      scope.common = response
      scope.commonCache = scope.common
      scope.init() # firing off the directive logic after we grab and store the cache
    
    scope.init = ->
      # assign the answer if it exists
      if scope.answer?.data?
        scope.selected = scope.answer.data
        # assign initial common ethnicities
        scope.common = $filter('filter')(scope.commonCache, {option_id: '!'+scope.selected.optionId}) # ! operator does a 'without' operation

    # add selected ethnicity
    scope.add = (ethnicity) ->
      scope.selected = Restangular.stripRestangular ethnicity
      scope.common = $filter('filter')(scope.commonCache, {option_id: '!'+scope.selected.option_id})

    # remove selected ethnicity
    scope.remove = (ethnicity) ->
      scope.selected = null
      scope.common = scope.commonCache

    # bypassing CSS error where border shows up if empty <ul> has any whitespaces in it
    scope.getBorder = ->
      "border: none" if scope.selected is null

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}

    scope.$watch 'selected', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()
)

