intakesModule = angular.module('intakesModule')
intakesModule.controller 'IntakeController', ($scope, $rootScope, $state, $stateParams, $resource, $log, $timeout, $sce, $window, AuthService, IntakesService, FormResponsesService, AgreementsService) ->
  $log = $log.getInstance('IntakeController')
  $log.log '$stateParams', $stateParams
  $scope.environment = AuthService.getEnvironment()
  $scope.intake = {}
  $scope.answersLoaded = false
  $scope.intake_id = $stateParams.intake_id
  $scope.header = {}

  # load the intake and display it on the page
  $scope.init = ->
    IntakesService.find($stateParams.intake_id, 'appointment_intakes', AuthService.getSurveyParams()).then (response) ->
      $log.info 'IntakeController intake', response.data
      $scope.intake = response.data
      $scope.appointment = $scope.intake.appointment
      $scope.form_response = $scope.intake.formResponse
      $scope.form = $scope.intake.formResponse.form
      $scope.question_responses = $scope.intake.formResponse.questionResponses
      $scope.insurance_plans = $scope.intake.insurancePlans
      $scope.submittedAt = $scope.form_response.submittedAt

      # mimic .then() callback here w/ $timeout
      $timeout ->
        $rootScope.$emit('info-module-update', $scope.buildHeader())
      , 0

      # load the answers if they exist in the question_responses object, but only do it once.
      $scope.$parent.answers = $scope.question_responses if !$scope.answersLoaded
      $scope.answersLoaded = true

  # Determine the header based on the intake type and
  # return the header object to display in the info-module
  $scope.buildHeader = ->
    if $scope.form_response.isSubmitted
      headerText = 'Review submitted information'
    else
      headerText = 'Review and submit patient information'

    if AuthService.isSurvey()
      # survey header
      $scope.header = {
        headerText: headerText
        steps: $scope.form.components.length
        step: 0
        previewMode: false
      }
    else if AuthService.isAppointment()
      # appointment header
      $scope.header = {
        providerProfileGuid: $scope.appointment.providerProfileGuid
        providerName: $scope.appointment.providerFirstName + " " + $scope.appointment.providerLastName
        headerText: headerText
        dateTime: $scope.appointment.startDateTimeUtc
        steps: $scope.form.components.length
        step: 0
        previewMode: false
      }
    return $scope.header

  $scope.getQuestionHeaderFor = (question) ->
    header = IntakesService.getQuestionHeaderFor(question.component)
    if header is false then question.label else header

  $scope.getAnswerFor = (question) ->
    answer = _.find($scope.$parent.answers, {formComponentGuid: question.id})
    return answer?.data

  $scope.submitLater = ->
    if !$scope.header.previewMode
      $log.info 'submit later btn clicked'
      $state.go('intakes.submitted', {intake_id: $stateParams.intake_id})

  $scope.submit = ->
    # submit the apt
    $scope.intake.formResponse.questionResponses = $scope.$parent.answers
    # update the isSubmitted boolean
    $scope.intake.formResponse.isSubmitted = true
    $log.info 'submitting form response, updating isSubmitted to true', $scope.intake
    FormResponsesService.put($scope.intake.formResponse).then (response) ->
      $log.info 'form response submitted', response
      # then, redirect on success to /appoinment_id/submitted
      $state.go('intakes.submitted', {intake_id: $stateParams.intake_id})

  $scope.init()