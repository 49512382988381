# /symptoms/autocomplete
intakesModule = angular.module('intakesModule')
intakesModule = angular.module('intakesModule').factory 'RaceService', (Restangular, AuthService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_PATIENT_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'getList'
        list = data.race_options
        return list

  _formService = restAngular.all("race_options")

  getAll: ->
    _formService.getList()
