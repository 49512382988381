intakesModule = angular.module('intakesModule')
intakesModule.directive('answerEmergencyContactInfo', ($log) ->
  templateUrl: "patient/intakes/questions/pre-built/emergency-contact-info/a-emergency-contact-info.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
  }
  link: (scope, elem, attrs) ->
    scope.emergencyContact = scope.answers
    # check if answers are empty - omit null and empty string values for a key
    scope.placeholder = "-" if _.isEmpty _.omit(scope.answers, _.isEmpty)
)

