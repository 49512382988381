# /symptoms/autocomplete
intakesModule = angular.module('intakesModule')
intakesModule = angular.module('intakesModule').factory 'SymptomsService', (Restangular, AuthService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().CLINICAL_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'getList'
        list = data.symptoms
        return list
      if operation is 'get'
        list = data.symptoms
        return list

  _formService = restAngular.all("")

  autocomplete: (query, maxResults) ->
    _formService.customGETLIST('autocomplete/symptoms', {q: query, max_results: maxResults})

  getRelated: (selected, maxResults) ->
    # if the user puts in a symptom that is not in the autocomplete results then we need to strip the undefined snomed code
    # from the array as there isn't one
    snomedArray = _.map(selected, 'snomed')
    compactedSnomedArray = _.compact(snomedArray)
    compactedSnomedArray = [] if compactedSnomedArray is undefined

    _formService.customGET('autocomplete/related_symptoms', {'symptoms[]': compactedSnomedArray, max_results: maxResults})

  getCommon: ->
    return [
      {
        "name": "Dizziness",
        "value": "4143AE7F-2453-43ED-AA88-E04CB179C025",
        "snomed": "404640003"
      },
      {
        "name": "Ear ache",
        "value": "355B13E6-4763-41BB-813C-52607DB99AEA",
        "snomed": "16001004"
      },
      {
        "name": "Fainting",
        "value": "233CB745-4767-45F0-A608-AAD56949E0A6",
        "snomed": "271594007"
      },
      {
        "name": "Cough",
        "value": "DB45D2D8-F46E-4610-801B-5BF75D7B0367",
        "snomed": "49727002"
      },
      {
        "name": "Chest pain",
        "value": "EBE0592F-BBB2-49A8-9B48-7958BB0F0226",
        "snomed": "29857009"
      }
    ]