angular.module('providerModule').controller('ProviderController', [
'AuthService', 'EhrTokenService', 'PhrTokenService', '$location', '$state',
function(AuthService, EhrTokenService, PhrTokenService, $location, $state) {
    function authenticateEhrProvider(sessionKey) {
        return EhrTokenService.find(sessionKey)
            .then(function(response) {
                return PhrTokenService.find(response.data.token);
            }, function(e) {
                if (e.status === 401) {
                    const environment = AuthService.getEnvironment();
                    if (environment === 'development' || environment === 'test') {
                        $state.go('dev_landing_page', {});
                    } else {
                        $state.go('patientErrors', { error: 'session_timeout' });
                    }
                }
            });
    }

    const {
        patientPracticeGuid,
        route,
        sessionKey
    } = $location.search() || {};

    if (sessionKey) {
        AuthService.setSessionKey(sessionKey);
        authenticateEhrProvider(sessionKey)
            .then(function(ehrAuthData) {
                if (ehrAuthData) {
                    AuthService.setAuthToken(ehrAuthData.access_token);
                    AuthService.setPatientPracticeGuid(patientPracticeGuid);
                    AuthService.setProvider(ehrAuthData);
                    AuthService.initializeAuthenticatedSession();
                    if (route) {
                        $location.path(route);
                    } else {
                        $state.go('provider.forms');
                    }
                }
            });
    } else {
        $state.go('patientErrors', { error: 'session_timeout' });
    }
}]);