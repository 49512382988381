intakesModule = angular.module('intakesModule')
intakesModule = angular.module('intakesModule').factory 'IntakesService', ($state, Restangular, AuthService, $log) ->
  $log = $log.getInstance('IntakesService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().INTAKE_ENDPOINT_URL)
    Configurer.setErrorInterceptor (response) ->
      # for PhrUser: intake already claimed by another user, redirect to patient errors
      if AuthService.isPhrUser()
        if response.status == 403
          if AuthService.isSurvey()
            if _.isArray(response.data.errors) && response.data.errors.length > 0 && response.data.errors[0].code?
              $state.go('patientErrors', { error: response.data.errors[0].code })
            else
              $state.go('patientErrors', { error: 'already_claimed_survey' })
          else if AuthService.isAppointment()
            if _.isArray(response.data.errors) && response.data.errors.length > 0 && response.data.errors[0].code?
              $state.go('patientErrors', { error: response.data.errors[0].code })
            else
              $state.go('patientErrors', { error: 'already_claimed_appointment' })
        if response.status == 404
          $state.go('patientErrors', { error: 'appointment_not_found'} )

        # this also lives in app.run.coffee - but these don't cascade so adding here as well
        # for PhrUser: auth credentials are bad (session timeout) go to intake login page
        if response.status == 401
          AuthService.expireAuthToken()
          if (AuthService.getEnvironment() == 'development' || AuthService.getEnvironment() == 'test')
            $state.go('dev_landing_page', {})
          else
            $state.go('patientErrors', { error: 'session_timeout' })

  _intakesService = restAngular.all("")

  # given the component name, retrieve it's header text here
  getQuestionHeaderFor: (key) ->
    questionHeaders = {
      "personalInfo": "What is the patient's name, date of birth and sex?",
      "contactInfo": "What is the patient's contact information?",
      "preferredLanguage": "What is the patient's preferred language?",
      "ethnicity": "What is the patient's ethnicity?",
      "race": "What is the patient's race?",
      "symptoms": "What are the patient's symptoms?",
      "medications": "What are the patient's medications?",
      "allergies": "What allergies does the patient have?",
      "insurance": "What is the patient's insurance?",
      "smokingStatus": "What is the patient's smoking status?",
      "emergencyContactInfo": "What is the patient's emergency contact information?",
      "patientSignature": "Please read and sign the document"
    }
    return questionHeaders[key] || false

  find: (id, surveyType, surveyParams) ->
    return _intakesService.one(surveyType, id).get(surveyParams)

  put: (intake_id, form_response) ->
    id = String(intake_id)
    return _intakesService.one('appointment_intakes').customPUT(form_response, id)
