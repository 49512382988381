intakesModule = angular.module('intakesModule')
intakesModule.controller('IntakesController', ($rootScope, $scope, $state, $stateParams, $resource, $log, AuthService)->
  $log = $log.getInstance('IntakesController')
  $scope.environment = AuthService.getEnvironment()
  $scope.answers = []
  $scope.isAgreementRequired = AuthService.getIsAgreementRequired()

  if !AuthService.hasValidAuthToken()
    AuthService.setIsPhrUser(true)
    AuthService.initializeAuthenticatedSession()

  $scope.$on 'patient-answer-updated', (event, answer) ->
    $scope.answers = _.reject $scope.answers, {'formComponentGuid': answer.formComponentGuid}
    $scope.answers.push(answer)
    $log.info 'answer updated', answer
)

