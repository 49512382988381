intakesModule = angular.module('intakesModule')
intakesModule.directive('answerRace', ($log) ->
  templateUrl: "patient/intakes/questions/intake-index-answer.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
  }
  link: (scope, elem, attrs) ->
    scope.answer_string = _.map(scope.answers, 'value')?.join(', ') or '-'
)

