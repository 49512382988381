intakesModule = angular.module('intakesModule')
intakesModule.controller 'PreviewFormQuestionController', ($scope, $state, $stateParams, $rootScope, $log, $timeout, IntakesService, FormService)->
  $log = $log.getInstance('PreviewFormQuestionController')

  FormService.find($stateParams.form_id).then (response) ->
    $log.info 'form GET', response
    $scope.form = response.forms
    $scope.question = _.filter($scope.form.components, {'id': $stateParams.question_id})[0]
    $scope.answer = _.filter($scope.$parent.answers, {'id': $stateParams.question_id})[0]
    $scope.answers = $scope.$parent.answers
    $log.log $scope.question
    $scope.header = {
    #    providerProfileGuid: $scope.appointment.providerProfileGuid
      providerProfileGuid: 'TODO: add'
      providerName: '<doctor name>'
      headerText: IntakesService.getQuestionHeaderFor($scope.question.component) || $scope.question.label
      dateTime: '<appointment date>'
      steps: $scope.form.components.length
      step: _.indexOf($scope.form.components, $scope.question) + 1
      previewMode: true
    }

    # mimic .then() callback here w/ $timeout
    $timeout ->
      $rootScope.$emit('info-module-update', $scope.header)
    , 10