intakesModule = angular.module('intakesModule')
intakesModule.directive('answerInsurances', ($log) ->
  templateUrl: "patient/intakes/questions/pre-built/insurances/a-insurances.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
  }
  link: (scope, elem, attrs) ->
    scope.sort = (insurances) ->
      primary = _.filter insurances, {'type': 'Primary'}
      secondary = _.filter insurances, {'type': 'Secondary'}
      tertiary = _.filter insurances, {'type': 'Tertiary'}
      other = _.filter insurances, {'type': 'Other'}
      return _.flatten [primary, secondary, tertiary, other]

    scope.selfPay = scope.answers?.selfPay
    scope.insurances = scope.sort(scope.answers?.insurances) or []
)

