intakesModule = angular.module('intakesModule')
intakesModule.controller 'PreviewFormController', ($scope, $rootScope, $state, $stateParams, $resource, $log, $timeout, $location, AuthService, IntakesService, FormService)->
  if AuthService.hasValidAuthToken()
    FormService.find($stateParams.form_id).then (response) ->
      $scope.form = response
      $scope.header = {
    #    providerProfileGuid: $scope.appointment.providerProfileGuid
        providerProfileGuid: 'TODO: add'
        providerName: '<doctor name>'
        headerText: 'Review and submit patient information'
        dateTime: '<appointment date>'
        steps: $scope.form.forms.components.length
        step: 0
        previewMode: true
      }

      # mimic .then() callback here w/ $timeout
      $timeout ->
        $rootScope.$emit('info-module-update', $scope.header)
      , 0

    $scope.getQuestionHeaderFor = (question) ->
      header = IntakesService.getQuestionHeaderFor(question.component)
      if header is false then question.label else header

    $scope.getAnswerFor = (question) ->
      answer = _.find($scope.$parent.answers, {formComponentGuid: question.id})
      return answer?.data
  else
    queryParams = $location.search()
    sessionKey = queryParams.sessionKey || AuthService.getSessionKey()
    url = '/provider?route=' +
      $location.$$path + 
      '&sessionKey=' + 
      sessionKey
    $location.url(url)