window.intake.config((RestangularProvider, $httpProvider, logExProvider) ->
  # Custom logging is handled via the logEx angular package: https://github.com/lwhiteley/AngularLogExtender/wiki/01.-Intro
  # logExProvider.disableDefaultColors(true)
  # logExProvider.overrideLogMethodColors({error: 'color:red;', info: 'color:purple;', log: 'color:blue;'})
  # logExProvider.restrictLogMethods(['log', 'info'])
  isLoggingEnabled = gon.env isnt 'production'
  logExProvider.enableLogging(isLoggingEnabled)
  logExProvider.useDefaultLogPrefix(false)
  logExProvider.overrideLogPrefix (className) ->
    $injector = angular.injector(["ng"])
    $filter = $injector.get("$filter")
    separator = " -> "
    # format = "MMMM-dd-yyyy-h:mm:ssa"
    format = "h:mm:ssa"
    now = $filter("date")(new Date(), format)
    "" + now + ((if not angular.isString(className) then "" else "::" + className)) + separator

#  RestangularProvider.addRequestInterceptor (data, operation, what) ->
#    return data
#
#  RestangularProvider.addResponseInterceptor (data, operation, what) ->
#    return data
)