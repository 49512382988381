import './assets/components/provider';
import './assets/components/patient';
import './assets/components/appointment';
import './assets/components/global';
import './assets/components/preview';
import './assets/components/root';
import './assets/stylesheets/app.sass';
require('./assets/javascripts');

// _.contains is removed in newer lodash, map to _.includes for restangular
_.contains = _.includes;

if (window.gon && window.gon.env === 'development') {
    window.landingPageModule = angular.module('LandingPageModule', []);
    require('./assets/components/landing-page/');
}

import '../public/apple-touch-icon.png';
import '../public/apple-touch-icon-precomposed.png';
import '../public/favicon.ico';