angular.module('intakesModule').factory 'AgreementsService', ($rootScope, $log, Restangular, AuthService) ->
  $log = $log.getInstance('AgreementsService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().AGREEMENT_ENDPOINT_URL)

  _agreementsService = restAngular.all("")

  findAll: (agreementType) ->
    return _agreementsService.get('agreement_statuses', {guids: AuthService.getPhrUserGuid(), guid_type: 'Profile', agreement_type: agreementType})

  post: (agreementType, isAccepted, signedBy, subjectGuid) ->
    request = {
      data: {
        extra_data: {
          email: AuthService.getSurveyParams().email
          signed_by: signedBy
          subject_guid: subjectGuid
        }
        patient_practice_guid: AuthService.getSurveyParams().patient_practice_guid
        accepted: isAccepted
        guid: AuthService.getPhrUserGuid()
        guid_type: 'Profile'
      }
    }
    $log.log 'POST to /acknowledgments', request

    return _agreementsService.one(agreementType).post('acknowledgments', request)

#    gon.surveyParams

#    email: "lstone@practicefusion.com"
#    form_type: "OAB"
#    options: Object
#      is_agreement_required: "true"
#    patient_practice_guid: "C40C4B72-081B-4D9D-8014-9A174CD11E6A"
#    practice_guid: "24175501-C3EB-472D-AB65-B2F8EECDFBAD"
#    provider_guid: ""
#    signature: "397094af0c84d7f289ee7e4c37f8c18d13c4d211dcacd0acf0f0b70dfaacdd59"
#    survey_guid: "f916d42d-f33f-4ff7-9067-a620f70be459"

# specs for POST in agreement endpoint
#  "data" => {
  #  "extra_data" => {
    #  "patient_practice_guid" => "3FBD8E97-0842-425C-A5DE-8E0CC9705143",
    #  "email" => 'foo@bar.com',
    #  'signed_by' => 'foo bar'
  #  },
  #  "accepted" => true,
  #  "guid" => "2e963262-6063-4720-bfdd-a9cab2c3c489",
  #  "guid_type" => "Profile"
#  }