angular.module('globalHelpersModule').factory 'NotificationService', ($rootScope, $timeout, $log) ->
  emit: (errorType, payload) ->
    if errorType is 'intake-warning'
      $rootScope.$broadcast 'intake-warning', payload

    if errorType is 'intake-success'
      $rootScope.$broadcast 'intake-success', payload

    if errorType is 'intake-info'
      $rootScope.$broadcast 'intake-info', payload
