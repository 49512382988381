intakesModule = angular.module('intakesModule')
intakesModule.controller 'PreviewAnswersController', ($scope, $rootScope, $state, $stateParams, $filter, $log, $timeout, IntakesService, FormResponsesService)->
  $log = $log.getInstance('PreviewAnswersController')
  $log.log $stateParams
  FormResponsesService.find($stateParams.form_response_id).then (response) ->
    $scope.form_response = response.formResponses
    $scope.header = {
  #    providerProfileGuid: $scope.appointment.providerProfileGuid
      providerProfileGuid: 'TODO: add'
      providerName: '<doctor name>'
      headerText: 'Review and submit patient information'
      dateTime: '<appointment date>'
      steps: $scope.form_response.form.components.length
      step: 0
      previewMode: true
    }

    # mimic .then() callback here w/ $timeout
    $timeout ->
      $rootScope.$emit('info-module-update', $scope.header)
    , 0

    # for tablets we 'pop out' the answers tab into a new window so the page title will be visible.
    # let's set that with the form response submission date here
    $rootScope.pageTitle = 'Intake for ' + $filter('date')($scope.form_response.submittedAt, 'MM/d/yyyy')

  $scope.getQuestionHeaderFor = (question) ->
    header = IntakesService.getQuestionHeaderFor(question.component)
    if header is false then question.label else header

  $scope.getAnswerFor = (question) ->
    answer = _.find($scope.form_response.questionResponses, {formComponentGuid: question.id})
    return answer?.data