intakesModule = angular.module('intakesModule').factory 'FormResponsesService', (Restangular, AuthService, $log) ->
  $log = $log.getInstance('FormResponsesService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().INTAKE_ENDPOINT_URL)
    Configurer.addRequestInterceptor (data, operation, what) ->
      $log.info 'request', operation, data
      return data
    Configurer.addResponseInterceptor (data, operation, what) ->
      $log.info 'response', operation, data
      return data

  _formResponsesService = restAngular.all("form_responses")

  put: (form_response) ->
    wrappedResponse = {formResponses: form_response}
    return _formResponsesService.customPUT(wrappedResponse, form_response.intakeFormResponseGuid)

  find: (id) ->
    return _formResponsesService.get(id, {practiceGuid: AuthService.getPracticeGuid(), patientPracticeGuid: AuthService.getPatientPracticeGuid()})