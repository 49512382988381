# instantiate our application named 'intake' and include dependencies via injection
intakeModules = [
  'angular-jwt',
  'builder',
  'builder.components',
  'builder.provider',
  'formsModule',
  'globalHelpersModule',
  'infinite-scroll',
  'intakesModule',
  'log.ex.uo',
  'ngAnimate',
  'ngTouch',
  'ngResource',
  'providerModule',
  'restangular',
  'templates',
  'textAngular',
  'ui.bootstrap',
  'ui.bootstrap.showErrors',
  'ui.mask',
  'ui.router',
  'validator.rules'
]

if window.gon?.env == 'development'
  intakeModules.push('landingPageModule')

# adding intake app to window so we can reference in other files for configuration and routing of application.
# have to do this because coffeescript wraps things in a closure automatically
intake = angular.module('intake', intakeModules)
window.intake = intake
