globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive 'spinner', ($rootScope) ->
  restrict: 'A'

  link: (scope, element, attrs) ->
    $rootScope.$watch('loading', ->
      if $rootScope.loading
        scope.insertSpin()
      else
        scope.removeSpin()
    )

    scope.insertSpin = ->
      element.addClass('loading')
      element.find('.scrim').remove()
      element.prepend('<div class="scrim"></div>')


    scope.removeSpin = ->
      element.removeClass('loading')
      element.find('.scrim').remove()

