formsModule = angular.module('formsModule')
formsModule = angular.module('formsModule').factory 'FormService', (Restangular, AuthService, $log) ->
  $log = $log.getInstance('FormService')

  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().INTAKE_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'getList'
        list = data[what]
        list.metadata = data.metadata if data.metadata
        $log.info operation, list
        return list
      else if operation is 'get'
        object = data
        object.metadata = data.metadata if data.metadata
        $log.info operation, object
        return object
      else
        $log.info operation, data
        return data

  _formService = restAngular.all("forms")

  findAll: (searchScope, page, pageSize) ->
    _formService.getList({scope:searchScope, 'page[number]':page, 'page[size]':pageSize})

  find: (id) ->
    _formService.one(id).get()

  # for making a form copy from a shared form make sure to:
  # update provider_guid
  # remove form id

  new: ->
    Restangular.setBaseUrl(AuthService.getConfigData().INTAKE_ENDPOINT_URL).restangularizeElement _formService, this._buildElement(), ''

  _buildElement: ->
    return {
      "forms": {
        "title": "New form",
        "createdBy": "",
        "providerGuid": "",
        "practiceGuid": "",
        "sharedAt": "",
        "isShared": false,
        "isActive": false,
        "components": []
      }
    }
