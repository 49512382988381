intakesModule = angular.module('intakesModule')
intakesModule.directive('questionRadioButtons', ($rootScope, $log) ->
  templateUrl: "patient/intakes/questions/custom/radio-buttons/q-radio-buttons.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.selected = ""

    # assign the answer if it exists
    if scope.answer?.data?
      scope.selected = scope.answer.data

    # setting to true adds margin top to nav buttons
    scope.editState = ->
      return true

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.selected}

    scope.$watch 'selected', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

)

