intakesModule = angular.module('intakesModule')
intakesModule.directive('answerTextArea', ($log) ->
  templateUrl: "patient/intakes/questions/intake-index-answer.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
    question: '=question'
  }
  link: (scope, elem, attrs) ->
    scope.answer_string = scope.answers or '-'
)

