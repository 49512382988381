intakesModule = angular.module('intakesModule').factory 'LanguagesService', (Restangular, AuthService, $log) ->
  restAngular = Restangular.withConfig (Configurer) ->
    # configure the Restangular object here
    Configurer.setBaseUrl(AuthService.getConfigData().PHR_PATIENT_ENDPOINT_URL)
    Configurer.addResponseInterceptor (data, operation, what) ->
      if operation is 'getList'
        list = data.language_options
        return list

  _languageService = restAngular.all("language_options")

  findAll: ->
    _languageService.getList()

  # TODO: get a common action in our endpoint and remove this once it's available
  # Although I doubt this will ever change
  getCommon: ->
    [
      {
        "option_id": 1,
        "value": "English",
        "is_active": true
      },
      {
        "option_id": 16,
        "value": "Spanish",
        "is_active": true
      },
      {
        "option_id": 3,
        "value": "German",
        "is_active": true
      },
      {
        "option_id": 12,
        "value": "French",
        "is_active": true
      },
      {
        "option_id": 55,
        "value": "Chinese",
        "is_active": true
      }
    ]