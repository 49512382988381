intakesModule = angular.module('intakesModule')
intakesModule.directive('questionPatientSignature', ($rootScope, $log) ->
  templateUrl: "patient/intakes/questions/custom/patient-signature/q-patient-signature.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.document = {}
    scope.document.copy = scope.question.options[0]

    # assign the answer if it exists
    if scope.answer?.data?
      scope.document.signature = scope.answer.data.signature
      scope.document.date = scope.answer.data.date

    scope.$watch 'document.signature', (newValue, oldValue) ->
      console.log newValue
      if !_.isEmpty scope.document.signature
        scope.document.date = new Date()
      else
        scope.document.date = null

    # setting to true adds margin top to nav buttons
    scope.editState = ->
      return true

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:{signature:scope.document.signature, date:scope.document.date}}

    scope.$watch 'document.signature', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

)

