intakesModule = angular.module('intakesModule')
intakesModule.directive('answerCheckboxes', ($log) ->
  templateUrl: "patient/intakes/questions/custom/checkboxes/a-checkboxes.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
    question: '=question'
  }
  link: (scope, elem, attrs) ->
    scope.checkedAnswers = _.without(scope.answers, false)
)

