# for this directive to work there must
# be a label directive preceding the <input>

globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive 'slideLabel', ($log, $timeout) ->
  restrict: 'A'

  link: (scope, element, attrs) ->
    label = element.parent().find('label')
    label.addClass('slide-label') # apply styles to label

    element.on 'keyup change', ->
      # if it's not a <select>, else if it's a <select> and the option selected is the first one.
      # note: requires a blank first option to work on select boxes
      label.addClass('float-label-active') if element.val() != ''
      label.removeClass('float-label-active') if element.val() == ''

    element.on 'focus', ->
      label.addClass 'float-label-focus'

    element.on 'blur', ->
      label.removeClass 'float-label-focus'
      # remove the label if the user doesn't make a selection on selects
      label.removeClass('float-label-active') if element.val() == '?' || (element.val() == '0' && element.val() == '') && element.prop('type') == 'select-one'

    scope.$on 'float-labels-on', ->
      $('[slide-label]').parent().find('label').addClass('float-label-active')

    scope.$on 'float-labels-off', ->
      $('[slide-label]').parent().find('label').removeClass('float-label-active')

    $timeout ->
      if element.val().length && element.val() != '?'
        label.addClass('float-label-active')
    , 10
