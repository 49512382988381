# /symptoms/autocomplete
intakesModule = angular.module('intakesModule').factory 'RelationshipService', (Restangular, $log) ->
  findAll: ->
    return [
      'Associate',
      'Brother',
      'Caregiver',
      'Child',
      'Employee',
      'Employer',
      'Extended family',
      'Father',
      'Foster child',
      'Friend',
      'Grandchild',
      'Grandparent',
      'Guardian',
      'Handicapped dependent',
      'Life partner',
      'Manager',
      'Mother',
      'Natural child',
      'Other',
      'Parent',
      'Self',
      'Sibling',
      'Sister',
      'Spouse',
      'Stepchild',
      'Trainer'
    ]