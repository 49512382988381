intakesModule = angular.module('intakesModule')
intakesModule.directive('answerPatientSignature', ($log, $state, $window, AuthService) ->
  templateUrl: "patient/intakes/questions/custom/patient-signature/a-patient-signature.html"
  restrict: 'E'
  scope: {
    answers: '=answers'
    question: '=question'
    formResponse: '=formResponse'
    isAnswerMode: '=isAnswerMode' # because we can show this answer in the doctor preview mode and the consumer mode we'll need to turn off/on UI elements. We do that by passing this in from the parent controller
  }
  link: (scope, elem, attrs) ->
    if scope.answers?.signature
      scope.answer_string = 'Document read and signed'
    else
      scope.answer_string = '-'

    scope.viewPatientSignature = ->
      route = $state.href('preview.forms.answer', { 
        form_response_id: scope.formResponse.intakeFormResponseGuid, 
        question_response_id: scope.question.id 
      })
      url = AuthService.getConfigData().EHR_INTAKE_URL +
        '/provider?route=' +
        window.encodeURIComponent(route) + 
        '&sessionKey=' + 
        AuthService.getSessionKey() +
        '&patientPracticeGuid=' +
        AuthService.getPatientPracticeGuid()

      $window.open(url, '_blank')
      return null
)

