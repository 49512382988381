intakesModule = angular.module('intakesModule')
intakesModule.directive('questionInsurances', ($rootScope, $state, $log, $timeout, $http, BillingService, ModalService) ->
  templateUrl: "patient/intakes/questions/pre-built/insurances/q-insurances.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.nextQuestionIndex = (_.indexOf scope.form.components, scope.question) + 1
    scope.nextQuestionId = scope.form.components[scope.nextQuestionIndex]?.id || false

    scope.insurances = []
    scope.selfPay = true
    scope.insuranceTypes = [ "Primary", "Secondary", "Tertiary", "Other" ]
    scope.insurance = {}
    scope.placeholders = {}
    scope.placeholders.payor = 'Insurance company'
    # define maxRelated first so all /related calls have it defined
    scope.max_results = 10
    scope.plans = []

    scope.init = ->
      if scope.answer?.data
        scope.insurances = scope.sort(scope.answer.data.insurances)
        scope.selfPay = scope.answer.data.selfPay

    scope.isInsuranceFullyFilledOut = ->
      if !_.isEmpty(scope.insurance.payerId) && !_.isEmpty(scope.insurance.planId) && !_.isEmpty(scope.insurance.id) && !_.isEmpty(scope.insurance.effectiveDate) && !_.isEmpty(scope.insurance.type)
        return true
      else
        return false

    scope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
      if !_.isEmpty(scope.insurance) && !scope.isInsuranceFullyFilledOut() # not empty and not fully filled out
        event.preventDefault()

        # $log.log scope.insuranceForm
        if scope.insuranceForm.effectiveDate.$error.date
          # show a modal
          modalOptions =
            closeButtonText: "Stay on this page"
            actionButtonText: "Continue to next question"
            headerText: "Information has to be in valid format."
            subheaderText: "Fill in required data to continue."
            bodyText: "You will lose partially filled insurance data if you continue. Do you wish to continue to next question?"
        else
          modalOptions =
            closeButtonText: "Stay on this page"
            actionButtonText: "Continue to next question"
            headerText: "All fields are required."
            subheaderText: "Fill in required data to continue."
            bodyText: "You will lose partially filled insurance data if you continue. Do you wish to continue to next question?"

        ModalService.showModal({templateUrl: "patient/intakes/questions/pre-built/insurances/_q-all-fields-required-modal.html"}, modalOptions).then (result) ->
          # "ok" is a string passed in by clicking the ok button in the modal HTML
          if result == "ok"
            scope.insurance = {} # empty the insurance object (and the form)
            $state.go(toState.name, toParams)

      else if !_.isEmpty(scope.insurance) && scope.isInsuranceFullyFilledOut() # not empty but fully filled out
        event.preventDefault()

        # just save the form and move on
        scope.add(scope.insurance)

        # adding a timeout here to allow the scope.add() to fire and complete
        $timeout ->
          scope.$emit("patient-navigates", {state: toState.name, params: toParams})
        , 250

    # ng-options for insurance type request
    scope.unselectedInsuranceTypes = ->
      selected = _.map(scope.insurances, 'type')
      selected = _.without(selected, "Other")
      return _.difference(scope.insuranceTypes, selected)

    # choose selected payer
    scope.addPayors = (payer) ->
      scope.insurance.payerId = payer.value
      scope.insurance.payerString = payer.label
      scope.setPlans(payer.value)

    # choose selected plan
    scope.addPlans = (plan) ->
      scope.insurance.planString = plan.label
      scope.insurance.planId = plan.value

    # add selected insurance
    scope.add = (insurance) ->
      scope.insurances.push(insurance) # if scope.insurance-form.$valid
      scope.insurances = scope.sort(scope.insurances)
      scope.insurance = {}
      scope.plan = ''
      scope.payer = ''
      scope.resetForm()
      scope.$broadcast('float-labels-off')

    # remove selected insurance
    scope.remove = (insurance) ->
      scope.insurances = _.without(scope.sort(scope.insurances), insurance)

    # remove selected insurance and make it the currently editable one
    scope.edit = (insurance) ->
      scope.remove(insurance)
      scope.insurance = insurance

      # handling payer separately because their ng-model
      # is tied to the typeahead, not the insurance object
      scope.payer = insurance.payerString
      plan = { "label": insurance.planString, "value": insurance.planId }
      scope.setPlans(insurance.payerId).then (response) ->
        scope.plan = plan
        scope.addPlans(scope.plan)
        scope.resetForm()
        scope.$broadcast('float-labels-on')

    # reset the error state of the show-errors directive
    scope.resetForm = ->
      scope.$broadcast('show-errors-reset')

    # get autocomplete payors list for query
    scope.getPayors = (query) ->
      BillingService.autocompletePayors(query, scope.max_results).then (response) ->
        return response.payers

    # get list of plans for selected payor
    scope.setPlans = (payerId) ->
      BillingService.findPlansForPayor(payerId).then (response) ->
        scope.plans = response.plans
        scope.plan = scope.plans[0]
        scope.addPlans(scope.plan)

    scope.editState = ->
      scope.insurance.payerId is undefined or ''

    # this is an initial test for keeping track of answers
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:{insurances:scope.insurances, selfPay:scope.selfPay}}

    scope.$watch 'insurances', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()
      if object.length > 0
        scope.placeholders.payor = 'Add another insurance'
      else
        scope.placeholders.payor = 'Insurance company'

    scope.$watch 'selfPay', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

    # sort insurances by primary, secondary, tertiary, and then other
    scope.sort = (insurances) ->
      primary = _.filter insurances, {'type': 'Primary'}
      secondary = _.filter insurances, {'type': 'Secondary'}
      tertiary = _.filter insurances, {'type': 'Tertiary'}
      other = _.filter insurances, {'type': 'Other'}
      return _.flatten [primary, secondary, tertiary, other]

    scope.init()
)

