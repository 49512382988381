intakesModule = angular.module('intakesModule')
intakesModule.directive('questionCheckboxes', ($rootScope, $log) ->
  templateUrl: "patient/intakes/questions/custom/checkboxes/q-checkboxes.html"
  restrict: 'E'
  scope: {
    form: '=form'
    question: '=question'
    answer: '=answer' # '=' means maintain two-way data-binding between directive and parent scope
  }
  link: (scope, elem, attrs) ->
    scope.options = []

    # we're only storing true or false for each question.
    # let's make sure that we're providing a default for each checkbox.
    # this will allow us to store just the boolean and not a key and keep the data in sync
    _.each(scope.question.options, (option) ->
      scope.options.push(false)
    )

    # assign the answer if it exists
    if scope.answer?.data?
      scope.options = scope.answer.data

    # setting to true adds margin top to nav buttons
    scope.editState = ->
      return true

    # compute the answer for this question
    scope.computeAnswers = ->
      return {formComponentGuid:scope.question.id, data:scope.options}

    scope.$watch 'options', (object) ->
      scope.$emit 'patient-answer-updated', scope.computeAnswers()

)

