import doctorDefault from 'images/patient/intakes/info-module/doctor_default.png';
import doctorPreview from 'images/patient/intakes/info-module/doctor_default_preview.png';

intakesModule = angular.module('intakesModule')
intakesModule.directive('intakesInfoModule', ($rootScope, $log, $state, $stateParams, AuthService) ->
  restrict: 'E'
  templateUrl: "patient/intakes/info-module/info-module-directive-template.html"
  link: (scope, elem, attrs) ->
    scope.$log = $log.getInstance('intakesInfoModule')

    scope.intake_id = $stateParams.intake_id # patient mode
    scope.form_id = $stateParams.form_id # provider preview mode
    scope.progressBarPercentage = '0'
    scope.doctorDefaultPhotoUrl = doctorDefault
    scope.doctorPreviewPhotoUrl = doctorPreview
    scope.showEditText = false
    scope.hasProviderPhoto = AuthService.hasProviderPhotoUrl()

    $rootScope.$on('info-module-update', (event, data) ->
      scope.infoModule = data
      scope.addProfilePictureText = "You do not have a profile picture set in your EHR. To add your profile picture, go to your EHR > Settings > Users > Your name > Upload your picture"
      # Try and build the provider photo url
      scope.$log.log 'hasProviderPhotoUrl', AuthService.hasProviderPhotoUrl()
      if AuthService.hasProviderPhotoUrl() # isProvider
        scope.doctorPhotoUrl = AuthService.getProviderPhotoUrl()
      else if scope.infoModule?.providerProfileGuid && scope.infoModule.providerProfileGuid != '' # isPhrUser
        scope.doctorPhotoUrl = AuthService.getBuiltProfilePhotoUrl(scope.infoModule.providerProfileGuid.toLowerCase())

      $log.info scope.infoModule
      scope.stateName = $state.current.name # update the stateName here too
      scope.calculateProgressBarWidth()
    )

    scope.$on 'fallback-image-loaded', (event, data) ->
      scope.showEditText = true
      scope.$apply() # actualSrc performs stuff outside of angular run loop. must force execute that here.
      scope.$log.info 'fallback-image-loading. failed to load:', data

    scope.calculateProgressBarWidth = ->
      scope.progressBarPercentage = ((scope.infoModule.step / scope.infoModule.steps) * 100) + '%'

    # send a patient navigation event when a patient goes to the intake overview
    # this event will trigger a PUT to save the current answers
    scope.sendNavigationEvent = (state, params) ->
      # must be rootScope here to update event listener in question controller
      $rootScope.$broadcast("patient-navigates", {state: state, params: params}) #if !scope.isPreviewMode
      scope.$log.info 'Navigate to -> overview '
)

