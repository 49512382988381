# for this directive to work there must
# be a label directive preceding the <input>

globalHelpersModule = angular.module('globalHelpersModule')
globalHelpersModule.directive 'toggleButton', ($log) ->
  restrict: 'E'
  transclude: true
  replace: true
  scope: {
    model: "=model"
    set: "=set"
  }
  link: (scope, element, attrs) ->
    scope.isActive = (value) ->
      if scope.model is value then return 'active'

  template:
    """
      <button type="button" class="btn btn-default" data-element="toggle-button" ng-model="model" ng-click="model=set; $event.stopPropagation()" ng-class="isActive(set)" ng-transclude></button>
    """