formsModule = angular.module('intakesModule')
formsModule.controller 'PatientErrorsController', ($scope, $rootScope, $state, $stateParams, $log, AuthService, $timeout, $window) ->

  $scope.redirectUrl = AuthService.getConfigData()?.PHR_HOME_URL
  $scope.redirectBtnText = "Go to your patient portal"
  $scope.showRedirectBtn = true

  $scope.setErrorMessage = ->
    switch $stateParams.error
      when 'appointment_not_found'
        $scope.errorTitle = 'This appointment could not be found'
        $scope.errorMessage = 'You cannot check in for an appointment that does not exist.'
      when 'appointment_in_past'
        $scope.errorTitle = 'This appointment occurred in the past'
        $scope.errorMessage = 'You cannot check in for a past appointment.'
      when 'appointment_cancelled'
        $scope.errorTitle = 'This appointment was canceled'
        $scope.errorMessage = 'You cannot check in for a canceled appointment.'
      when 'session_invalid'
        $scope.errorTitle = 'Your session is invalid'
        $scope.errorMessage = 'Please try starting intake again.'
      when 'session_timeout'
        $scope.errorTitle = 'Your session has timed out'
        $scope.errorMessage = 'Please try starting intake again.'
        $scope.redirectBtnText = "Go to your intake portal"
        $scope.redirectUrl = AuthService.getConfigData().INTAKE_LOGIN_URL + "/appointments/" + AuthService.getSubjectGuid()
      when 'session_timeout_ehr'
        $scope.errorTitle = 'Your EHR session has timed out'
        $scope.errorMessage = 'Please close this browser tab and login to your EHR.'
        $scope.showRedirectBtn = false
      when 'already_claimed_appointment'
        $scope.errorTitle = 'Another user has already started check-in for this appointment'
        $scope.errorMessage = 'You cannot check in for this appointment.'
      when 'not_authorized_for_appointment'
        $scope.errorTitle = 'Online check in for this appointment is not currently available'
        $scope.errorMessage = 'Please contact your healthcare provider to check in for the appointment.'
      when 'already_claimed_survey'
        $scope.errorTitle = 'This survey has been started by someone else.'
        $scope.errorMessage = 'You cannot fill out a survey started by someone else.'
      when 'declined_required_survey_agreement'
        $scope.errorTitle = 'You have declined this agreement'
        $scope.errorMessage = 'You will be redirected to your patient portal.'
        $scope.redirectBtnText = "Go to your patient portal"
        $scope.redirectUrl = AuthService.getConfigData().PHR_HOME_URL
      when 'required_survey_agreement_not_accepted'
        $scope.errorTitle = 'You have not accepted the required agreement for this survey.'
        $scope.errorMessage = 'You will be redirected to your patient portal.'
        $scope.redirectBtnText = "Go to your patient portal"
        $scope.redirectUrl = AuthService.getConfigData().PHR_HOME_URL
      when 'invalid_survey_request'
        $scope.errorTitle = 'This survey is invalid'
        $scope.errorMessage = 'You cannot complete an invalid survey.'
      when 'expired_survey_request'
        $scope.errorTitle = 'This survey has expired'
        $scope.errorMessage = 'You cannot complete an expired survey.'
      else
        $scope.errorTitle = 'Cannot retrieve your appointment'
        $scope.errorMessage = 'There was a problem retrieving your appointment.'

  $scope.redirectSeconds = 10

  $scope.redirectTimeout = ->
    $timeout ->
      $scope.redirectSeconds--
      if $scope.redirectSeconds == 0
        $log.log "redirecting..."
        $window.location.href = $scope.redirectUrl
      else
        $scope.redirectTimeout()
    , 1000

  $scope.setErrorMessage()

  # only automatically redirect if the redirectUrl is truthy and the button is shown
  if $scope.redirectUrl && $scope.showRedirectBtn
    $scope.redirectTimeout()
